<template>
    <input-component
        class="search-input"
        :class="{ 'with-value': value.length }"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:value', $event.target.value)"
    >
        <template v-if="(!value.length && isSearch) || forceDisplayIcons" #prefix>
            <search-icon-component @click="$emit('prefix-clicked')" />
        </template>

        <template #suffix>
            <close-icon-component
                v-if="value.length || forceDisplayIcons"
                @click="onCloseButton"
                class="close-icon"
            />
        </template>
    </input-component>
</template>

<script setup>
    import { Input as InputComponent } from 'ant-design-vue';
    import SearchIconComponent from '@/components/ui/icons/SearchIconComponent.vue';
    import CloseIconComponent from '@/components/ui/icons/CloseIconComponent.vue';

    const props = defineProps({
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        isSearch: {
            type: Boolean,
            default: false,
        },
        forceDisplayIcons: {
            type: Boolean,
            default: false,
        },
    });
    const emit = defineEmits(['update:value', 'prefix-clicked', 'suffix-clicked']);

    const onCloseButton = () => {
        emit('update:value', '');
        emit('suffix-clicked', props.value.length > 0);
    };
</script>

<style lang="scss">
    .search-input {
        background: #ffffff !important;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 32px !important;
        height: 36px !important;
        padding: 8px 16px !important;
        color: rgba(0, 0, 0, 0.3) !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        outline: none !important;
        animation: none !important;
        transition: none !important;
        box-shadow: none !important;
        margin-bottom: 24px;

        .ant-input {
            &::placeholder {
                font-weight: 500 !important;
                font-size: 12px !important;
                line-height: 18px !important;
            }
        }

        &.ant-input {
            &::placeholder {
                font-weight: 500 !important;
                font-size: 12px !important;
                line-height: 18px !important;
            }
        }

        .ant-input-prefix {
            margin-right: 11px;
        }

        .close-icon {
            color: var(--asset-color-text-main);
            cursor: pointer;
        }

        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.3) !important;
            outline: none !important;
            box-shadow: none !important;
            animation: none !important;
        }

        &:focus,
        &.with-value {
            border: 1px solid rgba(0, 0, 0, 0.3) !important;
            outline: none !important;
            box-shadow: none !important;
            animation: none !important;
            color: var(--asset-color-text-main);
        }
    }
</style>
