<template>
    <menu-component v-if="isLoggedIn" :is-loading="isLoading">
        <template #menu-items="{ close }">
            <div
                @click="
                    onLogout();
                    close();
                "
                class="popover-content__item"
            >
                <logout-icon-component class="item-icon" />
                <div class="item-text">Logout</div>
            </div>
        </template>

        <template #action-item>
            <ant-avatar :size="36" :src="userPicture">
                {{ userInitials }}
            </ant-avatar>
        </template>
    </menu-component>
</template>

<script setup>
    import MenuComponent from '@/components/ui/MenuComponent.vue';
    import LogoutIconComponent from '@/components/ui/icons/LogoutIconComponent.vue';

    import { storeToRefs } from 'pinia';
    import { useUserStore } from '@/stores/UserStore';
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { Avatar as AntAvatar } from 'ant-design-vue';

    const router = useRouter();

    const userStore = useUserStore();
    const { isLoggedIn, userData, userInitials } = storeToRefs(userStore);

    const userPicture = computed(() => {
        return userData.value.picture;
    });

    defineProps({
        isLoading: {
            type: Boolean,
            default: false,
        },
    });

    const onLogout = () => {
        userStore.logout().then(() => {
            router.push({ name: 'AuthLogin' });
        });
    };
</script>
