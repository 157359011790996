import axios from 'axios';

function getApplicationsList() {
    return axios.get(`/applications`).then(({ data }) => data);
}

function getApplicationSnapshots(params) {
    const getParams = new URLSearchParams(params).toString();
    return axios.get(`/application_snapshots?${getParams}`).then(({ data }) => data);
}

function getSnapshotsByAppId(appId) {
    return axios.get(`/applications/${appId}/snapshots`).then(({ data }) => data);
}

function getSnapshotBrands(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/brands`)
        .then(({ data }) => data);
}

function getSnapshotClusterLevels(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/levels`)
        .then(({ data }) => data);
}

function updateSnapshotClusterLevel(appId, snapshotId, clusterId, data) {
    return axios.patch(`/applications/${appId}/snapshots/${snapshotId}/levels/${clusterId}`, data);
}

function getSnapshotSDTags(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/tags`)
        .then(({ data }) => data);
}

function getSnapshotChannels(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/channels`)
        .then(({ data }) => data);
}

function getBrandsSamples(appId, snapshotId, params = {}) {
    const {
        brand_ids,
        cluster_level_ids,
        visual_tags,
        ocr_tags,
        search_terms,
        sd_tag_ids,
        channel_ids,
    } = params;
    delete params.brand_ids;
    delete params.cluster_level_ids;
    delete params.visual_tags;
    delete params.ocr_tags;
    delete params.search_terms;
    delete params.sd_tag_ids;
    delete params.channel_ids;

    let getParams = new URLSearchParams(params);

    if (visual_tags) {
        for (const visual_tag of visual_tags) {
            getParams.append('visual_tags[]', visual_tag);
        }
    }

    if (search_terms) {
        for (const search_term of search_terms) {
            getParams.append('search_terms[]', search_term);
        }
    }

    if (ocr_tags) {
        for (const ocr_tag of ocr_tags) {
            getParams.append('ocr_tags[]', ocr_tag);
        }
    }

    if (brand_ids) {
        for (const brand_id of brand_ids) {
            getParams.append('brand_ids[]', brand_id);
        }
    }

    if (cluster_level_ids) {
        for (const cluster_level_id of cluster_level_ids) {
            getParams.append('cluster_level_ids[]', cluster_level_id);
        }
    }

    if (sd_tag_ids) {
        for (const sd_tag_id of sd_tag_ids) {
            getParams.append('sd_tag_ids[]', sd_tag_id);
        }
    }

    if (channel_ids) {
        for (const channel_id of channel_ids) {
            getParams.append('channel_ids[]', channel_id);
        }
    }

    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/samples?${getParams}`)
        .then(({ data }) => data);
}

function getSnapshotVideos(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/videos`)
        .then(({ data }) => data);
}

function getSnapshotAssets(appId, snapshotId) {
    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/assets`)
        .then(({ data }) => data);
}

function getScatterPlotData(appId, snapshotId, params = {}) {
    const { brand_ids, cluster_level_ids, visual_tags, ocr_tags } = params;
    delete params.visual_tags;
    delete params.ocr_tags;
    delete params.brand_ids;
    delete params.cluster_level_ids;

    let getParams = new URLSearchParams(params);

    if (brand_ids) {
        for (const brand_id of brand_ids) {
            getParams.append('brand_ids[]', brand_id);
        }
    }

    if (cluster_level_ids) {
        for (const cluster_level_id of cluster_level_ids) {
            getParams.append('cluster_level_ids[]', cluster_level_id);
        }
    }

    if (visual_tags) {
        for (const visual_tag of visual_tags) {
            getParams.append('visual_tags[]', visual_tag);
        }
    }

    if (ocr_tags) {
        for (const ocr_tag of ocr_tags) {
            getParams.append('ocr_tags[]', ocr_tag);
        }
    }

    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/scatter/data?${getParams}`)
        .then(({ data }) => data);
}

function getBarPlotData(appId, snapshotId, params = {}) {
    const { brand_ids, cluster_level_ids, visual_tags, ocr_tags, sd_tag_ids, channel_ids } = params;
    delete params.visual_tags;
    delete params.ocr_tags;
    delete params.brand_ids;
    delete params.cluster_level_ids;
    delete params.sd_tag_ids;
    delete params.channel_ids;

    let getParams = new URLSearchParams(params);

    if (brand_ids) {
        for (const brand_id of brand_ids) {
            getParams.append('brand_ids[]', brand_id);
        }
    }

    if (cluster_level_ids) {
        for (const cluster_level_id of cluster_level_ids) {
            getParams.append('cluster_level_ids[]', cluster_level_id);
        }
    }

    if (visual_tags) {
        for (const visual_tag of visual_tags) {
            getParams.append('visual_tags[]', visual_tag);
        }
    }

    if (ocr_tags) {
        for (const ocr_tag of ocr_tags) {
            getParams.append('ocr_tags[]', ocr_tag);
        }
    }

    if (sd_tag_ids) {
        for (const sd_tag_id of sd_tag_ids) {
            getParams.append('sd_tag_ids[]', sd_tag_id);
        }
    }

    if (channel_ids) {
        for (const channel_id of channel_ids) {
            getParams.append('channel_ids[]', channel_id);
        }
    }

    return axios
        .get(`/applications/${appId}/snapshots/${snapshotId}/bar/data?${getParams}`)
        .then(({ data }) => data);
    //?group_by=1st_level
}

function getExploreApplicatiosSnapshots() {
    return axios.get(`/exploration/application_snapshots?per_page=2`).then(({ data }) => data);
}

function clearNovelty(appId, snapshotId) {
    return axios.post(`/applications/${appId}/snapshots/${snapshotId}/novelty_clearing`);
}

export {
    getApplicationsList,
    getApplicationSnapshots,
    getSnapshotsByAppId,
    getSnapshotBrands,
    getSnapshotClusterLevels,
    updateSnapshotClusterLevel,
    getBrandsSamples,
    getSnapshotVideos,
    getSnapshotAssets,
    getSnapshotSDTags,
    getSnapshotChannels,
    getScatterPlotData,
    getBarPlotData,
    getExploreApplicatiosSnapshots,
    clearNovelty,
};
