<template>
    <layout-header class="main-header">
        <img
            @click="onLogoClick"
            src="@/assets/img/main/logo.svg"
            class="story-logo"
            alt="StoryData Logo"
        />
        <div class="header-menu-wrapper">
            <router-link class="header-menu-wrapper__item" to="/explore">
                <compass-icon-component class="menu-item-icon" />
                Explore
            </router-link>
            <router-link class="header-menu-wrapper__item" to="/brands">
                <brands-icon-component class="menu-item-icon" />
                Brands
            </router-link>
            <router-link class="header-menu-wrapper__item" to="/playlists">
                <playlist-icon-component class="menu-item-icon" />
                Playlists
            </router-link>
            <router-link class="header-menu-wrapper__item" to="/apps">
                <grid-view-icon-component class="menu-item-icon" />
                Apps
            </router-link>
        </div>

        <div class="header-user-menu-wrapper">
            <global-search-component />
            <user-menu-component class="header-user-menu" />
        </div>
    </layout-header>
</template>

<script setup>
    import { LayoutHeader } from 'ant-design-vue';
    import PlaylistIconComponent from '@/components/ui/icons/PlaylistIconComponent.vue';
    import BrandsIconComponent from '@/components/ui/icons/BrandsIconComponent.vue';
    import CompassIconComponent from '@/components/ui/icons/CompassIconComponent.vue';
    import GridViewIconComponent from '@/components/ui/icons/GridViewIconComponent.vue';
    import UserMenuComponent from '@/components/menus/UserMenuComponent.vue';
    import GlobalSearchComponent from '@/components/ui/inputs/GlobalSearchComponent.vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    const onLogoClick = () => {
        router.push({ name: 'Explore' });
    };
</script>

<style scoped lang="scss">
    .main-header {
        height: 80px;
        background-color: var(--color-background);
        padding: 22px 60px;
        line-height: inherit;
        justify-content: space-between;
        display: flex;

        .story-logo {
            cursor: pointer;
            max-width: 126px;
            height: auto;
        }

        .header-menu-wrapper {
            display: flex;
            align-items: center;

            &__item {
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.7);
                padding: 8px 16px;
                margin-right: 8px;
                border-radius: 100px;
                display: flex;
                align-items: center;

                .menu-item-icon {
                    height: 20px;
                    margin-right: 8px;
                    display: none;
                }

                &:hover {
                    background: #e3f1fa;
                    border-radius: 100px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.active-link {
                    background: #e3f1fa;

                    .menu-item-icon {
                        display: flex;
                    }
                }
            }
        }

        .header-user-menu-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            //.user-avatar {
            //    width: 36px;
            //    height: 36px;
            //    border-radius: 50%;
            //}
        }

        @media (min-width: 1920.98px) {
            padding: 22px 8%;
        }
    }

    .user-avatar-2 {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #d3d3d4;
    }

    :deep(.header-user-menu) {
        flex: 1;
        max-width: 36px;
    }
</style>
