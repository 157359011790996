import { defineStore } from 'pinia';
import { playlistsApi } from '@/api';
import { TYPE_TO_REQUEST_MAPPER } from '@/enums/playlistType';

export const useAddToPlaylistModalStore = defineStore('AddToPlaylistModalStore', {
    state: () => ({
        isModalVisible: false,
        assetsToAdd: [],
        searchValue: '',
        playlistName: '',
        playlistsToAdd: [],
        searchedPlaylist: [],
        clearOnClose: false,
    }),
    getters: {
        assetToAddLength: (state) => state.assetsToAdd.length,
        selectedPlaylistsLength: (state) => state.playlistsToAdd.length,
        isSelected: (state) => (id) => !!state.playlistsToAdd.find((it) => it.id === id),
        isAssetSelected:
            (state) =>
            ({ assetId, assetType }) =>
                !!state.assetsToAdd.find((it) => it.id === assetId && it.assetType === assetType),
    },
    actions: {
        showModal(params = { clearOnClose: false }) {
            this.isModalVisible = true;
            const { clearOnClose } = params;
            this.clearOnClose = clearOnClose;
        },
        async searchPlaylists(params) {
            const { items } = await playlistsApi.getPlaylists(params);
            this.searchedPlaylist = items;
            return items;
        },
        async createPlaylist() {
            await playlistsApi.createPlaylist({ name: this.playlistName }).then((data) => {
                this.playlistsToAdd.push({ ...data, isNew: true });
            });
        },
        async addAssetToPlaylist() {
            let promises = [];
            for (let playlist of this.playlistsToAdd) {
                promises.push(playlistsApi.addAssetToPlaylist(playlist.id, this.assetsToAdd));
            }
            return Promise.all(promises);
        },

        addAssetToSend(asset) {
            this.assetsToAdd.push(asset);
        },
        removeAssetToSend({ assetId, assetType }) {
            this.assetsToAdd = this.assetsToAdd.filter(
                (it) => !(it.id === assetId && it.assetType === assetType)
            );
        },

        hideModal() {
            this.isModalVisible = false;
            this.searchValue = '';
            this.playlistName = '';
            this.playlistsToAdd = [];
            this.searchedPlaylist = [];
            if (this.clearOnClose) {
                this.assetsToAdd = [];
            }
        },
        addToPlaylist(playlist) {
            this.playlistsToAdd.push(playlist);
        },
        removeFromPlaylist(playlist) {
            this.playlistsToAdd = this.playlistsToAdd.filter((it) => it.id !== playlist.id);
        },
    },
});
