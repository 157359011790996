<template>
    <ant-button
        :disabled="disabled"
        :class="`sd-button sd-button-${typeButton} ${size === 'big' ? 'big-btn' : ''} ${
            rounded ? 'rounded' : ''
        } ${hover ? 'hover' : ''} ${theme}`"
    >
        <span v-if="isLeftSlotExist && !rounded" class="left-side-icon side-icon">
            <slot name="leftIcon"> </slot>
        </span>
        <span class="button-text">
            <slot></slot>
        </span>
        <span v-if="isRightSlotExist && !rounded" class="right-side-icon side-icon">
            <slot name="rightIcon"></slot>
        </span>
    </ant-button>
</template>

<script setup>
    import { Button as AntButton } from 'ant-design-vue';
    import { computed, useSlots } from 'vue';

    const slots = useSlots();
    defineProps({
        typeButton: {
            type: String,
            default: () => 'primary',
        },
        disabled: {
            type: [Boolean],
            default: false,
        },
        size: {
            type: String,
            default: 'small',
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'light',
        },
    });

    const isLeftSlotExist = computed(() => {
        return !!slots.leftIcon;
    });

    const isRightSlotExist = computed(() => {
        return !!slots.rightIcon;
    });
</script>

<style lang="scss">
    .sd-button {
        height: 36px !important;
        padding: 8px 16px !important;
        border-radius: 100px !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

        .button-text {
            font-weight: 700 !important;
            font-size: 12px !important;
            line-height: 20px !important;
            height: 20px !important;
        }

        .left-side-icon {
            margin-right: 8px !important;
            height: 20px !important;
        }

        .right-side-icon {
            margin-left: 8px !important;
            height: 20px !important;
        }

        &.rounded {
            width: 36px !important;
            padding: 0 !important;
            border-radius: 50% !important;
            justify-content: center !important;
            align-items: center !important;
        }

        &.big-btn {
            height: 44px !important;
            padding: 12px 24px !important;

            &.rounded {
                width: 44px !important;
            }
        }

        &.sd-button-primary {
            background: #003e51 !important;

            .button-text {
                color: #ffffff !important;
            }

            .side-icon {
                svg {
                    color: #ffffff !important;
                }
            }

            &:hover,
            &.hover {
                background: #336574 !important;
            }

            &:disabled {
                background: #5c8490 !important;
            }

            &.dark {
                background: #ffffff !important;

                .button-text {
                    color: #212127 !important;
                }

                .side-icon {
                    svg {
                        color: #212127 !important;
                    }
                }

                &:hover,
                &.hover {
                    background: #e7e9e9 !important;
                }

                &:disabled {
                    background: rgba(255, 255, 255, 0.1) !important;

                    .button-text {
                        color: #cfd2d3 !important;
                    }

                    .side-icon {
                        svg {
                            color: #cfd2d3 !important;
                        }
                    }
                }
            }
        }

        &.sd-button-secondary {
            background: #f3c300 !important;

            .button-text {
                color: #212127 !important;
            }

            .side-icon {
                svg {
                    color: #212127 !important;
                }
            }

            &:hover,
            &.hover {
                background: #e7bd14 !important;
            }

            &:disabled {
                background: #fcf0bf !important;

                .button-text {
                    color: #858787 !important;
                }

                .side-icon {
                    svg {
                        color: #858787 !important;
                    }
                }
            }

            &.dark {
                &:disabled {
                    background: rgba(255, 255, 255, 0.1) !important;
                }
            }
        }

        &.sd-button-grey {
            background: rgba(0, 0, 0, 0.07) !important;

            .button-text {
                color: #212127 !important;
            }

            .side-icon {
                svg {
                    color: #212127 !important;
                }
            }

            &:hover,
            &.hover {
                background-color: #d9d9d9 !important;
            }

            &:disabled {
                background-color: #d9d9d9 !important;

                .button-text {
                    color: #858787 !important;
                }

                .side-icon {
                    svg {
                        color: #858787 !important;
                    }
                }
            }

            &.dark {
                background: rgba(255, 255, 255, 0.1) !important;

                .button-text {
                    color: #ffffff !important;
                }

                .side-icon {
                    svg {
                        color: #ffffff !important;
                    }
                }

                &:hover,
                &.hover {
                    background-color: rgba(255, 255, 255, 0.2) !important;

                    .button-text {
                        color: #ffffff !important;
                    }

                    .side-icon {
                        svg {
                            color: #ffffff !important;
                        }
                    }
                }

                &:disabled {
                    background-color: rgba(255, 255, 255, 0.1) !important;
                }
            }
        }

        &.sd-button-outline {
            background: transparent !important;

            .button-text {
                color: #212127 !important;
            }

            .side-icon {
                svg {
                    color: #212127 !important;
                }
            }

            &:hover,
            &.hover {
                background-color: rgba(0, 0, 0, 0.07) !important;
            }

            &:disabled {
                background-color: transparent !important;

                .button-text {
                    color: #858787 !important;
                }

                .side-icon {
                    svg {
                        color: #858787 !important;
                    }
                }
            }

            &.dark {
                .button-text {
                    color: #ffffff !important;
                }

                .side-icon {
                    svg {
                        color: #ffffff !important;
                    }
                }

                &:hover,
                &.hover {
                    background-color: rgba(255, 255, 255, 0.1) !important;
                }
            }
        }

        &.sd-button-error {
            background: #ff4438 !important;

            .button-text {
                color: #ffffff !important;
            }

            .side-icon {
                svg {
                    color: #ffffff !important;
                }
            }

            &:hover,
            &.hover {
                background-color: #bf332a !important;
            }

            &:disabled {
                background-color: #ff968f !important;

                .side-icon {
                    svg {
                        color: #ff968f !important;
                    }
                }
            }
        }
    }
</style>
