import { useToast as uT } from 'vue-toastification';

import ToastComponent from '@/components/notifications/ToastComponet.vue';

import WarningIcon from '@/components/notifications/WarningIcon.vue';
import InfoIcon from '@/components/notifications/InfoIcon.vue';
import ErrorIcon from '@/components/notifications/ErrorIcon.vue';
import SuccessIcon from '@/components/notifications/SuccessIcon.vue';
import CloseIcon from '@/components/notifications/CloseIcon.vue';

const useToasts = () => {
    const toast = uT();
    return {
        error: (message) => {
            toast(
                {
                    component: ToastComponent,
                    props: {
                        toastTitle: 'Error',
                        toastMessage: message,
                    },
                },
                {
                    timeout: 5000,
                    icon: ErrorIcon,
                    toastClassName: 'toast-error',
                    closeButton: CloseIcon,
                }
            );
        },
        success: (message) => {
            toast(
                {
                    component: ToastComponent,
                    props: {
                        toastTitle: 'Success',
                        toastMessage: message,
                    },
                },
                {
                    timeout: 5000,
                    icon: SuccessIcon,
                    toastClassName: 'toast-success',
                    closeButton: CloseIcon,
                }
            );
        },
        info: (message) => {
            toast(
                {
                    component: ToastComponent,
                    props: {
                        toastTitle: 'Info',
                        toastMessage: message,
                    },
                },
                {
                    timeout: 5000,
                    icon: InfoIcon,
                    toastClassName: 'toast-info',
                    closeButton: CloseIcon,
                }
            );
        },
        warning: (message) => {
            toast(
                {
                    component: ToastComponent,
                    props: {
                        toastTitle: 'Warning',
                        toastMessage: message,
                    },
                },
                {
                    timeout: 5000,
                    icon: WarningIcon,
                    toastClassName: 'toast-warning',
                    closeButton: CloseIcon,
                }
            );
        },
    };
};

export default useToasts;
