//getApplicationsList
import { defineStore } from 'pinia';
import { applicationsApi } from '@/api';
import {
    VISUAL_CLUSTER_APP_ID,
    VISUAL_CLUSTER_APP_NAME,
    VISUAL_CLUSTER_APP_CODE,
    VISUAL_CLUSTER_APP_BG,
    WORD_EMBEDDING_APP_NAME,
    WORD_EMBEDDING_APP_ID,
    WORD_EMBEDDING_APP_CODE,
    WORD_EMBEDDING_APP_BG,
    EMOTION_MAP_APP_ID,
    EMOTION_MAP_APP_NAME,
    EMOTION_MAP_APP_CODE,
    EMOTION_MAP_APP_BG,
    MESSAGE_THEME_APP_ID,
    MESSAGE_THEME_APP_NAME,
    MESSAGE_THEME_APP_CODE,
    MESSAGE_THEME_APP_BG,
} from '@/enums/visualApps';
import { find as _find } from 'lodash';

export const useApplicationsStore = defineStore('ApplicationsStore', {
    state: () => ({
        isLoading: false,
        isLatestUpdatesLoading: false,
        applicationsList: [],
        latestUpdates: [],
        meta: null,
    }),
    getters: {
        maxPages: (state) => {
            if (!state.meta) return 1;
            return (
                Math.floor(state.meta.total / state.meta.perPage) +
                (state.meta.total % state.meta.perPage ? 1 : 0)
            );
        },
        currentPage: (state) => {
            if (!state.meta) return 0;
            return state.meta.currentPage;
        },
        canLoadMore: function () {
            return this.currentPage < this.maxPages;
        },
        getAppIdByAppName: (state) => (appName) => {
            const app = _find(state.applicationsList, { appName });
            return app.appId;
        },
    },
    actions: {
        async loadApplications() {
            this.isLoading = true;
            const { items } = await applicationsApi.getApplicationsList();
            this.applicationsList = this.processApplications(items);
            this.isLoading = false;
        },

        async loadApplicationSnapshots(params = {}) {
            this.isLatestUpdatesLoading = true;
            params.per_page = 5;
            params.page = 1; //this.currentPage + 1;

            const { items, meta } = await applicationsApi.getApplicationSnapshots(params);

            this.latestUpdates = items; ///[...this.latestUpdates, ...items];
            ///this.latestUpdates = [...this.latestUpdates, ...items];
            this.meta = meta;
            this.isLatestUpdatesLoading = false;
        },
        processApplications(appItems = []) {
            if (!Array.isArray(appItems)) this.applicationsList = [];
            const apps = appItems.map((appItem) => {
                const appName = appItem.name;
                switch (appName) {
                    case VISUAL_CLUSTER_APP_NAME:
                        return {
                            appId: appItem.id,
                            appName: VISUAL_CLUSTER_APP_ID,
                            appTitle: appItem.name,
                            appDescription: appItem.description,
                            appCode: VISUAL_CLUSTER_APP_CODE,
                            backgroundStyle: VISUAL_CLUSTER_APP_BG,
                        };
                    case WORD_EMBEDDING_APP_NAME:
                        return {
                            appId: appItem.id,
                            appName: WORD_EMBEDDING_APP_ID,
                            appTitle: appItem.name,
                            appDescription: appItem.description,
                            appCode: WORD_EMBEDDING_APP_CODE,
                            backgroundStyle: WORD_EMBEDDING_APP_BG,
                        };
                    case EMOTION_MAP_APP_NAME:
                        return {
                            appId: appItem.id,
                            appName: EMOTION_MAP_APP_ID,
                            appTitle: appItem.name,
                            appDescription: appItem.description,
                            appCode: EMOTION_MAP_APP_CODE,
                            backgroundStyle: EMOTION_MAP_APP_BG,
                        };
                    case MESSAGE_THEME_APP_NAME:
                        return {
                            appId: appItem.id,
                            appName: MESSAGE_THEME_APP_ID,
                            appTitle: appItem.name,
                            appDescription: appItem.description,
                            appCode: MESSAGE_THEME_APP_CODE,
                            backgroundStyle: MESSAGE_THEME_APP_BG,
                        };
                    default:
                        return null;
                }
            });

            return apps.filter((it) => !!it);
        },

        clearNovelty(appId, snapshotId) {
            applicationsApi.clearNovelty(appId, snapshotId);
        },
    },
});
