import { defineStore } from 'pinia';
import { playlistsApi } from '@/api';
import { PLAYLIST_TYPE_ALL, TYPE_TO_REQUEST_MAPPER } from '@/enums/playlistType';
import { randomSeed } from '@/helpers/pagination';

const DEFAULT_LIMIT = 20;

export const usePlaylistsStore = defineStore('PlaylistsStore', {
    state: () => ({
        isLoading: false,
        playlists: [],
        currentTab: PLAYLIST_TYPE_ALL,
        sort_by: '',
        sort_direction: '',
        meta: null,
        filters: {},
        sort_seed: randomSeed(),
        abortController: null,
    }),
    getters: {
        maxPages: (state) => {
            if (!state.meta) return 1;
            return (
                Math.floor(state.meta.total / state.meta.perPage) +
                (state.meta.total % state.meta.perPage ? 1 : 0)
            );
        },
        currentPage: (state) => {
            if (!state.meta) return 0;
            return state.meta.currentPage;
        },
        canLoadMore: function () {
            return this.currentPage < this.maxPages;
        },
    },
    actions: {
        async loadPlaylists(params = {}, infinity = false) {
            this.isLoading = true;
            if (this.abortController) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();

            if (!params.per_page) {
                params.per_page = DEFAULT_LIMIT;
            }
            params.page = this.currentPage + 1;
            if (!params.type) {
                params.type = TYPE_TO_REQUEST_MAPPER[this.currentTab];
            }

            if (!params.sort_by && this.sort_by) {
                params.sort_by = this.sort_by;
            }

            if (!params.sort_direction && this.sort_direction) {
                params.sort_direction = this.sort_direction;
            }

            if (!params.sort_seed && this.sort_seed) {
                params.sort_seed = this.sort_seed;
            }
            Object.entries(this.filters)?.forEach((filter) => {
                const [key, value] = filter;
                params[key] = value;
            });

            const { meta, items } = await playlistsApi.getPlaylists(
                params,
                this.abortController.signal
            );
            if (infinity) {
                this.playlists = [...this.playlists, ...items];
            } else {
                this.playlists = items;
            }

            this.meta = meta;
            this.abortController = null;
            this.isLoading = false;
        },
        async loadInfinity(params) {
            await this.loadPlaylists(params, true);
            return this.meta;
        },

        clearPlaylistItemsNovelty(playlistId) {
            return playlistsApi.clearPlaylistItemsNovelty(playlistId);
        },
        unsubscribeFromPlaylist(playlistId, filterPlaylist = true) {
            return playlistsApi.unsubscribeFromPlaylist(playlistId).then(() => {
              filterPlaylist && (this.playlists = this.playlists.filter((it) => it.id !== playlistId));
            });
        },

        deletePlaylist(playlistId) {
            return playlistsApi.deletePlaylist(playlistId).then(() => {
                this.playlists = this.playlists.filter((it) => it.id !== playlistId);
            });
        },
        updateInvitations(playlistId, invitations) {
            const index = this.playlists.findIndex((it) => it.id === playlistId);
            if (index >= 0) {
                this.playlists[index].invitations = [
                    ...this.playlists[index].invitations,
                    ...invitations,
                ];
            }
        },

        resetPagination() {
            this.playlists = [];
            this.meta = null;
        },
    },
});
