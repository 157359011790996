import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/UserStore.js';
import { useAddToPlaylistModalStore } from '@/stores/modals/AddToPlaylistModalStore';
import { useApplicationsStore } from '@/stores/applicationStore';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link', // scrollBehavior() {
    //     return { top: 0 };
    // },
    routes: [
        {
            path: '',
            redirect: { name: 'Explore' },
        },
        {
            path: '/',
            redirect: { name: 'Explore' },
        },
        {
            path: '/explore',
            name: 'Explore',
            component: () => import('@/pages/explore/ExplorePageComponent.vue'),
            meta: { layout: 'HeaderFullWidthLayout', auth: true },
        },
        {
            path: '/login',
            name: 'AuthLogin',
            component: () => import('@/pages/auth/AuthLoginPageComponent.vue'),
            meta: { layout: 'EmptyFullWidthLayout' },
        },
        {
            path: '',
            component: () => import('@/pages/asset/AssetPageComponent.vue'),
            children: [
                {
                    name: 'ViewVideoAsset',
                    path: 'videos/:assetId/',
                    component: () => import('@/pages/asset/VideoAssetPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
                {
                    name: 'ViewImageAsset',
                    path: 'images/:assetId/',
                    component: () => import('@/pages/asset/ImageAssetPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
            ],
        },
        {
            path: '/playlists',
            component: () => import('@/pages/playlists/PlaylistsPageComponent.vue'),
            children: [
                {
                    name: 'Playlists',
                    path: '',
                    component: () =>
                        import('@/pages/playlists/PlaylistsDashboardPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
                {
                    name: 'PlaylistEdit',
                    path: ':playlistId/edit',
                    component: () => import('@/pages/playlists/PlaylistEditPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
                {
                    name: 'PlaylistDetail',
                    path: ':playlistId/:assetType?/:assetId(\\d+)?',
                    component: () => import('@/pages/playlists/PlaylistDetailsPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
            ],
        },
        {
            path: '/brands',
            component: () => import('@/pages/brands/BrandsPageComponent.vue'),
            children: [
                {
                    name: 'Brands',
                    path: '',
                    component: () => import('@/pages/brands/AllBrandsPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                },
                {
                    name: 'BrandDetail',
                    path: ':brandId(\\d+)',
                    component: () => import('@/pages/brands/BrandDetailsPageComponent.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: true },
                    props: true,
                },
            ],
        },
        {
            path: '/search/:searchValue',
            name: 'GlobalSearch',
            component: () => import('@/pages/search/GlobalSearchPageComponent.vue'),
            meta: { layout: 'HeaderFullWidthLayout', auth: true },
        },
        {
            path: '/apps',
            component: () => import('@/pages/apps/AppsPageComponent.vue'),
            children: [
                {
                    name: 'StoryDataApps',
                    path: '',
                    component: () => import('@/pages/apps/AppsDashboardComponentPage.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: false },
                },
                {
                    name: 'StoryDataApp',
                    path: ':appName/:appType/:snapshotId?',
                    props: true,
                    component: () => import('@/pages/apps/AppDetailPage.vue'),
                    meta: { layout: 'HeaderFullWidthLayout', auth: false },
                    beforeEnter: async (to, from, next) => {
                        const applicationsStore = useApplicationsStore();
                        if (!applicationsStore.applicationsList.length) {
                            await applicationsStore.loadApplications();
                            next();
                        } else {
                            next();
                        }
                    },
                },
            ],
        },

        { path: '/:pathMatch(.*)*', redirect: { name: 'Explore' } },
    ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
});

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    if (to.meta.auth && !userStore.isLoggedIn) next({ name: 'AuthLogin' });
    else if (to.name === 'AuthLogin' && userStore.isLoggedIn) next({ name: 'Explore' });
    else next();
});

router.afterEach(() => {
    const addToPlaylistModalStore = useAddToPlaylistModalStore();
    addToPlaylistModalStore.$reset();
});

export default router;
