const LOCAL_STORAGE_VOLUME_KEY = 'SD_PLAYER_VOLUME';

export default function VolumePlugin(player) {
    let volume = localStorage.getItem(LOCAL_STORAGE_VOLUME_KEY);
    if (volume > 1 || volume < 0 || typeof volume === 'undefined' || volume === null) {
        volume = 0.5;
    }
    player.volume(volume);

    player.on('volumechange', function () {
        localStorage.setItem(LOCAL_STORAGE_VOLUME_KEY, player.volume());
    });
}
