import { defineStore } from 'pinia';
import { useAssetDetailStore } from './AssetDetailStore.js';
import { ASSET_TYPE_IMAGE, ASSET_TYPE_VIDEO } from '@/enums/assetTypeEnum';
import { playlistsApi } from '@/api';
import { randomSeed } from '@/helpers/pagination';

export const useCurrentPlaylistStore = defineStore('currentPlaylistStore', {
    state: () => ({
        isLoading: false,
        playlist: {},
        playlistAssets: [],
        sort_by: '',
        sort_direction: '',
        startAsset: null,
        search: '',
        meta: null,
        isExpended: false,
        tracks: [],
        currentIndex: 0,
        sort_seed: randomSeed(),
    }),
    getters: {
        currentTracksOnPage: function () {
            return this.isExpended
                ? this.playlistAssets.slice(this.currentIndex)
                : this.playlistAssets.slice(this.currentIndex, 7 + this.currentIndex);
        },
        canPlayNext: (state) => state.currentIndex < state.playlistAssets.length - 1,
        canPlayPrev: (state) => state.currentIndex > 0,

        playlistName: (state) => state.playlist.name,
        assetCountTotal: (state) => state.playlist?.assetsStatistics?.total,
        assetCountResponseTotal: (state) => state.meta?.total || 0,
        maxPages: (state) => {
            if (!state.meta) return 1;
            return (
                Math.floor(state.meta.total / state.meta.perPage) +
                (state.meta.total % state.meta.perPage ? 1 : 0)
            );
        },
        currentPage: (state) => {
            if (!state.meta) return 0;
            return state.meta.currentPage;
        },
        canLoadMore: function () {
            return this.currentPage < this.maxPages;
        },
        nextAssetData: (state) => state.playlistAssets[state.currentIndex + 1],
    },

    actions: {
        async getPlaylistMainInfo(playlistId) {
            this.playlist = await playlistsApi.getPlaylistInfo(playlistId).then((data) => {
                return data;
            });
        },
        updatePlaylistInfo(playlistId, data) {
            return playlistsApi.updatePlaylist(playlistId, data).then(({ data }) => {
                this.playlist = data;
            });
        },

        async getPlaylistAssets(playlistId, params = {}, infinity = false) {
            this.isLoading = true;

            params.per_page = 20;
            params.page = this.currentPage + 1;

            if (this.search) {
                params.search = this.search;
            }

            if (!params.sort_seed && this.sort_seed) {
                params.sort_seed = this.sort_seed;
            }

            if (!params.sort_by && this.sort_by) {
                params.sort_by = this.sort_by;
            }

            if (!params.sort_direction && this.sort_direction) {
                params.sort_direction = this.sort_direction;
            }

            if (this.startAsset) {
                params['start_asset_id'] = this.startAsset.assetId;
                params['start_asset_type'] = this.startAsset.assetType;
            }
            const { items, meta } = await playlistsApi
                .getPlaylistAssets(playlistId, params)
                .then((data) => {
                    return data;
                });

            if (infinity) {
                this.playlistAssets = [...this.playlistAssets, ...items];
            } else {
                this.playlistAssets = items;
            }

            this.meta = meta;
            this.isLoading = false;
        },
        async loadInfinity(_params) {
            const { playlistId, ...params } = _params;
            if (this.isLoading) return;
            await this.getPlaylistAssets(playlistId, params, true);
            return this.meta;
        },

        removeAssetFromPlaylist(playlistId, data) {
            return playlistsApi.removeAssetFromPlaylist(playlistId, data).then(() => {
                data.forEach((asset) => {
                    this.playlistAssets = this.playlistAssets.filter((it) => {
                        return !(it.id === asset.id && it.assetType === asset.assetType);
                    });
                });
                this.meta.total -= data.length;
            });
        },
        async loadTrack({ assetType = null, assetId = null } = {}) {
            const assetDetailStore = useAssetDetailStore();
            let asset = null;
            const loadFromAsset = assetType && assetId;

            if (loadFromAsset) {
                asset = {
                    id: assetId,
                    assetType: assetType,
                };
            } else {
                asset = this.playlistAssets[this.currentIndex];
            }

            if (asset) {
                const url = `/playlists/${this.playlist.id}/${asset.assetType}/${asset.id}`;
                if (loadFromAsset) {
                    history.replaceState(history.state, '', url);
                } else {
                    history.pushState({}, '', url);
                }
                if (asset.assetType === ASSET_TYPE_VIDEO) {
                    await assetDetailStore.loadVideoAssetWithRecommedations(asset.id);
                } else if (asset.assetType === ASSET_TYPE_IMAGE) {
                    await assetDetailStore.loadImageAssetWithRecommedations(asset.id);
                }

                if (loadFromAsset) {
                    this.playlistAssets.unshift(assetDetailStore.assetData);
                }
            }
        },

        setCurrentTrack(track) {
            const index = this.playlistAssets.findIndex(
                (it) => it.id === track.id && it.assetType === track.assetType
            );
            if (index >= 0) {
                this.currentIndex = index;
                this.loadTrack().then(() => {});
            }
        },

        nextTrack() {
            this.currentIndex++;
            this.loadTrack().then(() => {});
        },
        prevTrack() {
            this.currentIndex--;
            this.loadTrack().then(() => {});
        },

        replayPlaylist() {
            this.currentIndex = 0;
            this.loadTrack().then(() => {});
        },

        resetPagination() {
            this.meta = null;
        },
        setSearch(search) {
            this.search = search;
        },
        setStartAsset(startAsset) {
            this.startAsset = startAsset;
        },
        resetParams() {},
        updateInvitations(playlistId, invitations) {
            if (this.playlist?.id === playlistId) {
                this.playlist.invitations = [...this.playlist.invitations, ...invitations];
            }
        },
        updateAssetStatistic() {
            if (this.playlist?.assetsStatistics?.total) {
                this.playlist.assetsStatistics.total--;
            }
        },
    },
});
