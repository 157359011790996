<template>
    <modal-component
        v-if="isModalVisible && playlistInfo"
        :max-height="575"
        @close="onClose"
        class="share-modal"
        header-text="Share playlist"
    >
        <div class="modal-content">
            <input-component
                :placeholder="'Search by name or email'"
                :is-search="true"
                v-model:value="searchValue"
            />

            <div style="max-height: 357px; height: 357px" class="subscribers-list scrollbar">
                <section-component
                    v-if="invitedLength && !searchResult.length"
                    class="subscribers-list-wrapper"
                    :section-title="'Selected'"
                >
                    <subscriber-item-component
                        v-for="subscriber in invited"
                        @inviting="onInvited"
                        @uninviting="onUninvited"
                        :from-search="true"
                        :subscriber="subscriber"
                        :key="`search-item-${subscriber}`"
                        :is-invited="isInvited(subscriber)"
                        ,
                        :is-subscribed="isSubscribed(subscriber.id)"
                    />
                </section-component>

                <section-component
                    v-if="searchResult.length"
                    class="subscribers-list-wrapper"
                    :section-title="'Search results'"
                >
                    <subscriber-item-component
                        v-for="subscriber in searchResult"
                        @inviting="onInvited"
                        @uninviting="onUninvited"
                        :from-search="true"
                        :subscriber="subscriber"
                        :is-pending="pendingInvitations.includes(subscriber.id)"
                        :key="`search-item-${subscriber.id}`"
                        :is-invited="isInvited(subscriber)"
                        :is-subscribed="isSubscribed(subscriber.id)"
                        :is-owned="isOwned(subscriber)"
                    />
                </section-component>

                <section-component
                    v-if="!searchResult.length && alreadySubscribed.length"
                    class="subscribers-list-wrapper"
                    :section-title="'Already subscribed'"
                >
                    <subscriber-item-component
                        v-for="subscriber in alreadySubscribed"
                        :subscriber="subscriber"
                        :key="`already-item-${subscriber.id}`"
                    />
                </section-component>

                <div
                    v-if="!invitedLength && !searchResult.length && !alreadySubscribed.length"
                    class="empty-state"
                >
                    <img
                        src="@/assets/img/playlists/empty-playlist.svg"
                        class="empty-state__image"
                    />
                    <div class="empty-state__title">Not shared yet</div>
                    <div class="empty-state__text">
                        Search for users to add them to the playlist
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="modal-footer">
                <button-component
                    v-if="!(!invitedLength && !searchResult.length && !alreadySubscribed.length)"
                    :disabled="invitedLength === 0"
                    :size="'big'"
                    class="footer-button"
                    @click="inviteUsers"
                    >{{ `Send invitation ${invitedLength > 0 ? `(${invitedLength})` : ''}` }}
                </button-component>
            </div>
        </template>
    </modal-component>
</template>

<script setup>
    import ModalComponent from '@/components/ui/ModalComponent.vue';
    import InputComponent from '@/components/ui/inputs/InputComponent.vue';
    import { useShareModalStore } from '@/stores/shareModalStore';
    import { storeToRefs } from 'pinia';
    import { computed, ref, watch } from 'vue';
    import ButtonComponent from '@/components/ui/ButtonComponent.vue';
    import SectionComponent from '@/components/asset/components/SectionComponent.vue';
    import SubscriberItemComponent from '@/components/modals/components/SubscriberItemComponent.vue';
    import { PLAYLIST_TYPE_OWNED } from '@/enums/playlistType';
    import useToasts from '@/plugins/toast';

    const shareModalStore = useShareModalStore();
    const $toast = useToasts();

    const {
        isModalVisible,
        playlistInfo,
        searchValue,
        invitedLength,
        isInvited,
        invited,
        isOwned,
        alreadySubscribed,
        pendingInvitations,
        isSubscribed,
    } = storeToRefs(shareModalStore);

    const searchResult = ref([]);

    watch(
        () => searchValue.value,
        async (val) => {
            if (val) {
                const searched = await shareModalStore.searchUsers({
                    search: val,
                });
                searchResult.value = searched;
            } else {
                searchResult.value = [];
            }
        }
    );

    const onClose = () => {
        shareModalStore.hideModal();
    };

    const onInvited = (itemId) => {
        shareModalStore.addToInvite(itemId);
    };

    const onUninvited = (itemId) => {
        shareModalStore.removeFromInvite(itemId);
    };

    const inviteUsers = () => {
        shareModalStore.inviteUsers().then((usersCount) => {
            const message =
                usersCount > 1
                    ? 'Users were successfully invited'
                    : 'User was successfully invited';
            $toast.success(message);
            shareModalStore.hideModal();
        });
    };
</script>

<style lang="scss">
    .share-modal {
        .modal-content {
            height: 100%;

            .subscribers-list {
                height: calc(100% - 60px);
                overflow-y: auto;
                margin: 0 -24px;

                .subscribers-list-wrapper {
                    padding: 0 24px;

                    &:nth-child(2) {
                        padding-top: 21px;
                    }
                }
            }

            .empty-state {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 74px;

                &__image {
                    width: 100px;
                    height: 100px;
                }

                &__title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #212127;
                    margin-top: 36px;
                }

                &__text {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.6);
                    max-width: 198px;
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }

        .modal-footer {
            display: flex;
            justify-content: center;

            .footer-button {
                width: 220px;
            }
        }
    }
</style>
