export const VISUAL_CLUSTER_APP_ID = 'visual-cluster';
export const VISUAL_CLUSTER_APP_NAME = 'Visual Cluster';
export const VISUAL_CLUSTER_APP_CODE = 'VC';
export const VISUAL_CLUSTER_APP_BG = 'linear-gradient(133.77deg, #4B83EF -3.43%, #6524D0 110.04%)';

export const WORD_EMBEDDING_APP_ID = 'word-embedding';
export const WORD_EMBEDDING_APP_NAME = 'Word Embedding';
export const WORD_EMBEDDING_APP_CODE = 'WE';
export const WORD_EMBEDDING_APP_BG = 'linear-gradient(133.33deg, #9527D8 37.92%, #7527D8 96.36%)';

export const EMOTION_MAP_APP_ID = 'audio-app';
export const EMOTION_MAP_APP_NAME = 'Audio App';
export const EMOTION_MAP_APP_CODE = 'AA';
export const EMOTION_MAP_APP_BG = 'linear-gradient(133.33deg, #eb3f3f 37.92%, #830000 96.36%)';

export const MESSAGE_THEME_APP_ID = 'message-themes';
export const MESSAGE_THEME_APP_NAME = 'Message Themes';
export const MESSAGE_THEME_APP_CODE = 'MT';
export const MESSAGE_THEME_APP_BG = 'linear-gradient(133.33deg, #3feb86 37.92%, #00835c 96.36%)';

export const VISUAL_APP_CODE_MAPPER = {};
VISUAL_APP_CODE_MAPPER[VISUAL_CLUSTER_APP_NAME] = VISUAL_CLUSTER_APP_CODE;
VISUAL_APP_CODE_MAPPER[WORD_EMBEDDING_APP_NAME] = WORD_EMBEDDING_APP_CODE;
VISUAL_APP_CODE_MAPPER[EMOTION_MAP_APP_NAME] = EMOTION_MAP_APP_CODE;
VISUAL_APP_CODE_MAPPER[MESSAGE_THEME_APP_NAME] = MESSAGE_THEME_APP_CODE;

export const VISUAL_APP_BG_MAPPER = {};
VISUAL_APP_BG_MAPPER[VISUAL_CLUSTER_APP_NAME] = VISUAL_CLUSTER_APP_BG;
VISUAL_APP_BG_MAPPER[WORD_EMBEDDING_APP_NAME] = WORD_EMBEDDING_APP_BG;
VISUAL_APP_BG_MAPPER[EMOTION_MAP_APP_NAME] = EMOTION_MAP_APP_BG;
VISUAL_APP_BG_MAPPER[MESSAGE_THEME_APP_NAME] = MESSAGE_THEME_APP_BG;

export const VISUAL_APP_NAME_ID_MAPPER = {};
VISUAL_APP_NAME_ID_MAPPER[VISUAL_CLUSTER_APP_ID] = VISUAL_CLUSTER_APP_NAME;
VISUAL_APP_NAME_ID_MAPPER[WORD_EMBEDDING_APP_ID] = WORD_EMBEDDING_APP_NAME;
VISUAL_APP_NAME_ID_MAPPER[EMOTION_MAP_APP_ID] = EMOTION_MAP_APP_NAME;
VISUAL_APP_NAME_ID_MAPPER[MESSAGE_THEME_APP_ID] = MESSAGE_THEME_APP_NAME;

export const VISUAL_CLUSTER_APP_ID_NAME_MAPPER = {};
VISUAL_CLUSTER_APP_ID_NAME_MAPPER[VISUAL_CLUSTER_APP_NAME] = VISUAL_CLUSTER_APP_ID;
VISUAL_CLUSTER_APP_ID_NAME_MAPPER[WORD_EMBEDDING_APP_NAME] = WORD_EMBEDDING_APP_ID;
VISUAL_CLUSTER_APP_ID_NAME_MAPPER[EMOTION_MAP_APP_NAME] = EMOTION_MAP_APP_ID;
VISUAL_CLUSTER_APP_ID_NAME_MAPPER[MESSAGE_THEME_APP_NAME] = MESSAGE_THEME_APP_ID;
