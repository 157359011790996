<template>
    <div class="toast-container">
        <div class="toast-type-title">{{ toastTitle }}</div>
        <div class="toast-message">{{ transformMessage }}</div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { capitalize as _capitalize } from 'lodash';

    const props = defineProps({
        toastTitle: {
            type: [String],
            required: true,
        },
        toastMessage: {
            type: [String],
            required: true,
        },
        toastClass: {
            type: [String],
            default: '',
        },
    });

    const transformMessage = computed(() => {
        return _capitalize(props.toastMessage);
    });
</script>

<style lang="scss" scoped>
    .toast-container {
        display: flex;
        flex-direction: column;
        margin: 12px 0;

        .toast-type-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #000000;
        }

        .toast-message {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #858787;
        }
    }
</style>
