import axios from 'axios';

function login(idToken) {
    return axios.post(`/auth/oauth/google`, { idToken });
}

function logout() {
    return axios.post(`/auth/signout`);
}

function getCurrentUser() {
    return axios.get(`/users/me`);
}

export { login, logout, getCurrentUser };
