import { saveAs } from 'file-saver';
import imagePath from '@/assets/img/player/check_circle.svg';

function insertButtons({ resourceUrl, el, fileName }) {
    if (el) {
        let controlBar = el.querySelector('.vjs-control-bar');
        let insertBeforeNode = el.querySelector('.vjs-fullscreen-control');

        let newButton = document.createElement('button');
        newButton.className = 'vjs-download-asset-control vjs-control vjs-button';

        let iconElement = document.createElement('span');
        iconElement.className = 'vjs-icon-placeholder vjs-icon-download-item';
        iconElement.ariaHidden = 'true';

        let buttonAltText = document.createElement('span');
        buttonAltText.className = 'vjs-control-text';
        buttonAltText.ariaLive = 'polite';
        buttonAltText.innerText = 'Download';

        newButton.title = 'Download';

        newButton.addEventListener('click', () => {
            iconElement.style.setProperty('mask-image', `url('${imagePath}')`);
            iconElement.style.setProperty('-webkit-mask-image', `url('${imagePath}')`);

            iconElement.style.setProperty('background-repeat', 'no-repeat');
            iconElement.style.setProperty('width', '16px');
            iconElement.style.setProperty('height', '16px');
            iconElement.style.setProperty(
                'transition',
                'mask-image 0.4s, width: 0.6s, height: 0.6s, -webkit-mask-image 0.4s'
            );
            newButton.style.setProperty('pointer-events', 'none');

            setTimeout(() => {
                iconElement.style.removeProperty('mask-image');
                iconElement.style.removeProperty('background-repeat');
                iconElement.style.removeProperty('width');
                iconElement.style.removeProperty('height');
                iconElement.style.removeProperty('-webkit-mask-image');
                newButton.style.removeProperty('pointer-events');
            }, 5000);
            downloadAsset(resourceUrl, fileName);
        });

        newButton.appendChild(iconElement);
        newButton.appendChild(buttonAltText);

        controlBar.insertBefore(newButton, insertBeforeNode);
    } else {
        console.error('No Element Tag');
    }
}

function downloadAsset(assetUrl, fileName) {
    try {
        const name = fileName.replace(/[\\/:"*?<>|%']+/g, '');
        saveAs(assetUrl, name);
    } catch (e) {
        console.error(e);
    }
}

export default function AssetDownloadPlugin(options) {
    insertButtons(options);
}
