<template>
    <div @click="onClickItem" :class="{ 'not-added': !isAdded }" class="playlist-item">
        <div class="playlist-item__info">
            <div class="info">
                <div class="playlist-item-name">
                    {{ playlistItem.name }}
                </div>
                <div class="playlist-item-stat">
                    <template v-if="isPrivate">
                        <div class="private-playlist info-text">
                            <lock-icon-component
                                class="icon-lock action-icon"
                                :width="16"
                                :height="16"
                            ></lock-icon-component>
                            Private playlist
                        </div>
                    </template>
                    <template v-else>
                        <div class="playlist-subscribers">
                            <a-avatar-group
                                :size="20"
                                style="margin-right: 8px"
                                class="subscribers-avatar-wrapper"
                            >
                                <a-avatar
                                    v-for="subscriber in subscribers.slice(0, 3)"
                                    :key="subscriber.id"
                                    class="playlist-subscribers__item"
                                    :src="subscriber.avatarUrl"
                                >
                                    {{ userInitials(subscriber.username) }}
                                </a-avatar>
                            </a-avatar-group>
                            <div class="subscribers-text info-text">
                                {{
                                    `${subscribers.length} subscriber${
                                        subscribers.length > 1 ? 's' : ''
                                    }`
                                }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="actions">
                <!--                <template v-if="fromSearch">-->
                <template v-if="isAdded">
                    <a-tooltip placement="topRight">
                        <template #title>Asset is already there</template>
                        <double-check-icon-component
                            style="color: var(--asset-color-text-main)"
                            class="icon-check action-icon"
                        />
                    </a-tooltip>
                </template>
                <template v-else>
                    <check-circle-icon-component
                        v-if="isSelected"
                        style="color: #003e51"
                        class="invited-action"
                    />
                    <plus-circle-icon-component v-else style="color: #003e51" class="add-action" />
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { Avatar as AntAvatar, Progress as ProgressComponent } from 'ant-design-vue';
    import LockIconComponent from '@/components/ui/icons/LockIconComponent.vue';

    import PlusCircleIconComponent from '@/components/ui/icons/PlusCircleIconComponent.vue';
    import CheckCircleIconComponent from '@/components/ui/icons/CheckCircleIconComponent.vue';
    import DoubleCheckIconComponent from '@/components/ui/icons/DoubleCheckIconComponent.vue';

    import { computed, ref } from 'vue';

    const isPrivate = computed(() => {
        return (
            props.playlistItem?.owner?.id === props.currentUser?.id &&
            props.playlistItem?.subscription?.subscribers?.length === 0
        );
    });

    const props = defineProps({
        playlistItem: {
            type: Object,
            default: () => ({}),
        },
        currentUser: {
            type: Object,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },

        isAdded: {
            type: Boolean,
            default: false,
        },

        fromSearch: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['check', 'uncheck']);

    const subscribers = computed(() => {
        return props.playlistItem?.subscription?.subscribers || [];
    });

    const userInitials = (name) => {
        const [firstName = '', lastName = ''] = name.split(' ');
        return `${firstName[0] || ''}${lastName ? '' + lastName[0] : ''}`;
    };

    const onClickItem = () => {
        if (!props.isAdded) {
            if (props.isSelected) {
                emit('uncheck', props.playlistItem);
            } else {
                emit('check', props.playlistItem);
            }
        }
    };
</script>

<style lang="scss">
    .playlist-item {
        display: flex;
        align-items: center;
        padding: 9px 0;

        &.not-added {
            cursor: pointer;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .info {
                width: calc(100% - 25px);

                .playlist-item-name {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    color: #212127;
                    height: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .playlist-item-stat {
                    margin-top: 2px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #858787;

                    .playlist-subscribers {
                        display: flex;

                        .subscribers-avatar-wrapper {
                            .playlist-subscribers__item {
                                border: 2px solid #ffffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .subscribers-text {
                        }
                    }

                    span {
                        color: #212127;
                    }

                    .pending {
                        color: #e7bd14;
                    }
                }
            }

            .actions {
                .pending-action {
                    color: #e7bd14;
                }

                .add-action {
                    color: #858787;
                    cursor: pointer;
                }

                .invited-action {
                    color: var(--asset-color-text-main);
                    cursor: pointer;
                }
            }
        }

        .info-text {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
            //margin-left: 8px;
        }
    }
</style>
