import { defineStore } from 'pinia';
import { videoApi, imageApi, collectionApi } from '@/api';
import { useAppStore } from '@/stores/appStore.js';
import { uniq as _uniq } from 'lodash';
import assetFieldByType from '@/helpers/assetFieldsByTypeMaping';
import { getAssetDisplayName } from '@/helpers/asset';

const DEFAULT_TAGS_LIMIT = 20; // if config does not exist

export const useAssetDetailStore = defineStore('AssetDetailStore', {
    state: () => ({
        assetData: null,
        isLoading: false,
        isRecommendationsLoading: false,
        recommendations: [],
        applicationSnapshots: [],
    }),
    getters: {
        processorDataField: (state) =>
            assetFieldByType('assetProcessorData', state.assetData?.assetType),

        googleVideoVisualData: function () {
            if (!this.assetData) return null;
            const processorData = this.assetData[this.processorDataField];
            const _googleVideoVisualData = processorData?.googleVideoVisualData;

            return _googleVideoVisualData ? JSON.parse(_googleVideoVisualData) : null;
        },

        assetCreationDate: (state) =>
            state.assetData?.adStartDate ? new Date(state.assetData.adStartDate) : null,

        assetVote: (state) => state.assetData?.vote?.like,

        assetTagsList: function () {
            if (!this.googleVideoVisualData) return null;
            let frameLabels = this.googleVideoVisualData.labelAnnotations?.frameLabels;
            if (frameLabels) {
                let tags = frameLabels.map((label) => {
                    return label?.entity?.description;
                });

                tags = _uniq(tags);

                return (
                    tags.slice(
                        0,
                        import.meta.env.VITE_APP_ASSET_VISUAL_TAGS_LIMIT || DEFAULT_TAGS_LIMIT
                    ) || []
                );
            } else {
                return [];
            }
        },

        assetColors: function () {
            if (!this.assetData) return null;
            const processorData = this.assetData[this.processorDataField];

            return processorData?.colorData ? JSON.parse(processorData.colorData) : null;
        },

        brandData: (state) => state.assetData?.brand,

        assetTitle: (state) => getAssetDisplayName(state.assetData, false),

        assetDescription: (state) => state.assetData?.description,

        assetType: (state) => state.assetData?.assetType,

        assetId: (state) => state.assetData?.id || state.assetData?.ID,

        sources: (state) => (state.assetData?.url ? [{ src: state.assetData?.url }] : null),
    },

    actions: {
        async loadVideoAsset(assetId) {
            this.assetData = null;
            this.isLoading = true;
            this.assetData = await videoApi.getVideoAsset(assetId);
            this.isLoading = false;

            if (this.assetData) {
                const appStore = useAppStore();
                const title = `${this.assetData.displayName || this.assetData.name} | StoryData`;
                appStore.setDocumentTitle(title);
            }
            return this.assetData;
        },
        async loadImageAsset(assetId) {
            this.assetData = null;
            this.isLoading = true;
            this.assetData = await imageApi.getImageAsset(assetId);
            this.isLoading = false;

            if (this.assetData) {
                const appStore = useAppStore();
                const title = `${this.assetData.displayName || this.assetData.name} | StoryData`;
                appStore.setDocumentTitle(title);
            }
            return this.assetData;
        },
        async getAssetRecommendations(filterId, excluded_asset_id = 0) {
            this.recommendations = [];
            this.isRecommendationsLoading = true;
            this.recommendations = await collectionApi.getAssetRecommendations(
                filterId,
                excluded_asset_id
            );
            this.isRecommendationsLoading = false;
        },

        async loadVideoAssetWithRecommedations(assetId) {
            const assetInfo = await this.loadVideoAsset(assetId);
            const filterId = assetInfo.filters ? assetInfo.filters[0]?.id : null;

            if (filterId) {
                await this.getAssetRecommendations(filterId, assetId);
            }
        },

        async loadImageAssetWithRecommedations(assetId) {
            const assetInfo = await this.loadImageAsset(assetId);
            const filterId = assetInfo.filters ? assetInfo.filters[0]?.id : null;

            if (filterId) {
                await this.getAssetRecommendations(filterId, assetId);
            }
        },

        async loadVideoExtras(assetID) {
            this.applicationSnapshots = [];
            this.isLoading = true;
            const { application_snapshots } = await videoApi.getVideoExtras(assetID);
            this.applicationSnapshots = application_snapshots;
            this.isLoading = false;
        },

        voteVideoAsset(assetId, vote) {
            return videoApi.voteVideoAsset(assetId, vote).then(() => {
                if (!this.assetData.vote) {
                    this.assetData.vote = { like: vote };
                } else {
                    this.assetData.vote.like = vote;
                }
            });
        },
        voteImageAsset(assetId, vote) {
            return imageApi.voteImageAsset(assetId, vote).then(() => {
                if (!this.assetData.vote) {
                    this.assetData.vote = { like: vote };
                } else {
                    this.assetData.vote.like = vote;
                }
            });
        },
        markVideoAssetViewed(assetId) {
            return videoApi.markVideoAssetAsViewed(assetId);
        },
        markImageAssetViewed(assetId) {
            return imageApi.markImageAssetAsViewed(assetId);
        },
    },
});
