<template>
    <layout class="layout">
        <main-header-component />
        <layout-content id="sd-layout-main" class="layout-main">
            <router-view :key="route.fullPath" />
        </layout-content>
        <share-playlist-modal-component />
        <add-to-playlist-modal-component />
        <add-assets-to-playlist-footer-component />
    </layout>
</template>

<script setup>
    import { LayoutContent, Layout } from 'ant-design-vue';
    import { useRoute } from 'vue-router';
    import MainHeaderComponent from '@/components/layout/MainHeaderComponent.vue';
    import SharePlaylistModalComponent from '@/components/modals/SharePlaylistModalComponent.vue';
    import AddToPlaylistModalComponent from '@/components/modals/AddToPlaylistModalComponent.vue';
    import AddAssetsToPlaylistFooterComponent from '@/components/layout/AddAssetsToPlaylistFooterComponent.vue';

    const route = useRoute();
</script>

<style lang="scss">
    .layout {
        min-height: 100vh;

        & .layout-main {
            padding: 0 60px 60px;
            background-color: var(--color-background);

            @media (min-width: 1920.98px) {
                padding: 0 8% 60px;
            }
        }
    }
</style>
