import axios from 'axios';

function getSearchResults(params) {
    const getParams = new URLSearchParams(params).toString();
    return axios.get(`/search?${getParams}`).then(({ data }) => data);
}

const paramsSD = {
    per_page: 1000,
    page: 1,
};

function getStoryDataFilters() {
    const getParams = new URLSearchParams(paramsSD).toString();
    return axios.get(`/filters?${getParams}`).then(({ data }) => data.items);
}

function getStoryDataCategories() {
    const getParams = new URLSearchParams(paramsSD).toString();
    return axios.get(`/categories?${getParams}`).then(({ data }) => data.items);
}

export { getSearchResults, getStoryDataFilters, getStoryDataCategories };
