<template>
    <modal-component
        v-if="isModalVisible"
        :max-height="575"
        @close="onClose"
        class="add-to-playlist-modal"
        header-text="Add to playlist"
    >
        <div class="modal-content">
            <input-component
                :placeholder="'Search for playlist'"
                :is-search="true"
                v-model:value="searchValue"
            />

            <div style="max-height: 255px; height: 255px" class="playlist-list scrollbar">
                <section-component
                    v-if="selectedPlaylists.length && !searchValue.length"
                    class="playlist-list-wrapper"
                    :section-title="'Selected Playlists'"
                >
                    <playlist-item-component
                        v-for="playlistItem in selectedPlaylists"
                        :key="playlistItem.id"
                        :playlist-item="playlistItem"
                        :current-user="userData"
                        :is-selected="isSelected(playlistItem.id)"
                        @check="onCheck"
                        @uncheck="onUncheck"
                    />
                    <div
                        style="height: 1px; width: 100%; background: #e7e9e9; margin-top: 16px"
                    ></div>
                </section-component>

                <section-component
                    v-if="!searchValue.length && filteredFromSelected.length"
                    class="playlist-list-wrapper"
                    :section-title="'Recent playlists'"
                >
                    <template v-if="playlists.length">
                        <playlist-item-component
                            v-for="playlistItem in filteredFromSelected"
                            :key="playlistItem.id"
                            :playlist-item="playlistItem"
                            :current-user="userData"
                            :is-selected="isSelected(playlistItem.id)"
                            :is-added="assetInPlaylists.includes(playlistItem.id)"
                            @check="onCheck"
                            @uncheck="onUncheck"
                        />
                    </template>
                    <template v-else>
                        <div class="empty-state">
                            <img
                                src="@/assets/img/playlists/no-playlists.svg"
                                class="empty-state__image no-playlists"
                            />
                            <div class="empty-state__title">You don’t have any playlists yet</div>
                        </div>
                    </template>
                </section-component>

                <section-component
                    v-if="searchValue.length"
                    class="playlist-list-wrapper"
                    :section-title="'Search results'"
                >
                    <template v-if="searchedPlaylist.length">
                        <playlist-item-component
                            v-for="playlistItem in searchedPlaylist"
                            :key="playlistItem.id"
                            :playlist-item="playlistItem"
                            :current-user="userData"
                            :is-selected="isSelected(playlistItem.id)"
                            :is-added="assetInPlaylists.includes(playlistItem.id)"
                            @check="onCheck"
                            @uncheck="onUncheck"
                        />
                    </template>

                    <template v-else>
                        <div class="empty-state">
                            <img
                                src="@/assets/img/playlists/empty-results.svg"
                                class="empty-state__image"
                            />
                            <div class="empty-state__title">No results found</div>
                            <div class="empty-state__text">
                                Try adjusting your search to find what you’re looking for
                            </div>
                        </div>
                    </template>
                </section-component>
            </div>
            <section-component class="section-add-playlist" :section-title="'Create new playlist'">
                <input-component :placeholder="'Playlist name'" v-model:value="playlistName" />
            </section-component>
        </div>
        <template #footer>
            <div class="modal-footer">
                <button-component
                    :disabled="!selectedPlaylistsLength && !playlistName.length"
                    :size="'big'"
                    @click="onClick(!!playlistName.length)"
                    class="footer-button"
                >
                    {{ playlistName.length ? 'Add and save changes' : 'Save changes' }}
                </button-component>
            </div>
        </template>
    </modal-component>
</template>

<script setup>
    import ModalComponent from '@/components/ui/ModalComponent.vue';
    import InputComponent from '@/components/ui/inputs/InputComponent.vue';
    import { useAddToPlaylistModalStore } from '@/stores/modals/AddToPlaylistModalStore';
    import { usePlaylistsStore } from '@/stores/playlistsStore';
    import { storeToRefs } from 'pinia';
    import { PLAYLIST_TYPE_OWNED } from '@/enums/playlistType';
    import PlaylistItemComponent from '@/components/modals/components/PlaylistItemComponent.vue';
    import { useUserStore } from '@/stores/UserStore';
    import { computed, watch } from 'vue';
    import { differenceWith as _differenceWith, isEqual as _isEqual } from 'lodash';

    import ButtonComponent from '@/components/ui/ButtonComponent.vue';
    import SectionComponent from '@/components/asset/components/SectionComponent.vue';
    import useToasts from '@/plugins/toast';

    const addToPlaylistModalStore = useAddToPlaylistModalStore();
    const playlistsStore = usePlaylistsStore();
    const $toast = useToasts();

    const {
        isModalVisible,
        searchValue,
        playlistName,
        playlistsToAdd,
        searchedPlaylist,
        assetsToAdd,
        isSelected,
        selectedPlaylistsLength,
        assetToAddLength,
    } = storeToRefs(addToPlaylistModalStore);

    const { playlists } = storeToRefs(playlistsStore);

    const userStore = useUserStore();
    const { userData } = storeToRefs(userStore);

    const isSingleAsset = computed(() => {
        return assetsToAdd.value.length === 1;
    });

    const selectedPlaylists = computed(() => {
        return playlistsToAdd.value.filter((it) => !it.isNew);
    });

    const assetInPlaylists = computed(() => {
        if (isSingleAsset.value) {
            return assetsToAdd.value[0]?.playlistIds || [];
        } else {
            return [];
        }
    });

    const onClose = () => {
        addToPlaylistModalStore.hideModal();
        //addToPlaylistModalStore.$reset();
    };

    const onClick = async (createPlaylist = false) => {
        if (createPlaylist) {
            await addToPlaylistModalStore.createPlaylist();
        }
        await addToPlaylistModalStore.addAssetToPlaylist().then(() => {
            $toast['success'](
                `${assetToAddLength.value} asset${
                    assetToAddLength.value > 1 ? 's were' : ' was'
                } successfully added to ${playlistsToAdd.value.length} playlist${
                    playlistsToAdd.value.length > 1 ? 's' : ''
                }`
            );
            addToPlaylistModalStore.$reset();
        });
    };

    const onCheck = (playlist) => {
        addToPlaylistModalStore.addToPlaylist(playlist);
    };

    const onUncheck = (playlist) => {
        addToPlaylistModalStore.removeFromPlaylist(playlist);
    };

    const filteredFromSelected = computed(() => {
        return _differenceWith(playlists.value, playlistsToAdd.value, _isEqual);
    });

    watch(
        () => isModalVisible.value,
        (val) => {
            if (val) {
                playlistsStore.loadPlaylists({
                    page: 1,
                    per_page: 4,
                    type: PLAYLIST_TYPE_OWNED,
                });
            } else {
                playlistsStore.resetPagination();
            }
        }
    );

    watch(
        () => searchValue.value,
        (val) => {
            addToPlaylistModalStore.searchPlaylists({
                type: PLAYLIST_TYPE_OWNED,
                search: val,
            });
        }
    );
</script>

<style lang="scss">
    .add-to-playlist-modal {
        .modal-content {
            height: 100%;

            .playlist-list {
                height: calc(100% - 60px);
                overflow-y: auto;
                margin: 0 -24px;

                .playlist-list-wrapper {
                    padding: 0 24px;

                    margin-bottom: 21px;

                    .empty-state {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 20px;

                        &__image {
                            width: 100px;
                            height: 100px;
                        }

                        &__title {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: #212127;
                            margin-top: 36px;
                        }

                        &__text {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 22px;
                            color: rgba(0, 0, 0, 0.6);
                            max-width: 258px;
                            margin-top: 10px;
                            text-align: center;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .section-add-playlist {
                margin-top: 24px;

                .search-input {
                    margin-bottom: 0;
                }
            }
        }

        .modal-footer {
            display: flex;
            justify-content: center;

            .footer-button {
                width: 220px;
            }
        }
    }
</style>
