import { defineStore } from 'pinia';
import { usersApi, playlistsApi } from '@/api';
import { difference as _difference } from 'lodash';
import { usePlaylistsStore } from '@/stores/playlistsStore';
import { useCurrentPlaylistStore } from '@/stores/currentPlaylistStore';

export const useShareModalStore = defineStore('ShareModalStore', {
    state: () => ({
        searchValue: '',
        isModalVisible: false,
        playlistInfo: null,
        invited: [],
    }),
    getters: {
        alreadySubscribed: (state) => state.playlistInfo?.subscription?.subscribers || [],
        invitedLength: (state) => state.invited.length,
        isInvited: (state) => (invite) => {
            const s = state.invited.filter((it) => it.id === invite.id);
            return !!s.length;
        },
        pendingInvitations: function () {
            const subscibedIds = this.alreadySubscribed.map((it) => it.id);
            const invitationsIds = this.playlistInfo?.invitations
                .filter((it) => it.status === 'pending')
                .map((it) => it.invited?.id);
            return _difference(invitationsIds, subscibedIds);
        },
        isSubscribed: function (state) {
            return (id) => {
                const subscibedIds = state.alreadySubscribed.map((it) => it.id);
                return subscibedIds.includes(id);
            };
        },
        isOwned: (state) => (invite) => {
          return state.playlistInfo.owner.id === invite.id
        },
    },
    actions: {
        showModal(playlistInfo) {
            this.isModalVisible = true;
            this.playlistInfo = playlistInfo;
        },
        hideModal() {
            this.isModalVisible = false;
            this.playlistInfo = null;
            this.invited = [];
            this.searchValue = '';
        },
        async searchUsers(params) {
            const { items } = await usersApi.getUserList(params);
            return items;
        },
        addToInvite(invite) {
            this.invited.push(invite);
        },
        removeFromInvite(invite) {
            this.invited = this.invited.filter((it) => it.id !== invite.id);
        },
        inviteUsers() {
            const userIds = this.invited.map((it) => it.id);
            return playlistsApi
                .inviteUsersToPlaylist(this.playlistInfo.id, { userIds })
                .then((items) => {
                    this.playlistInfo.invitations = [...this.playlistInfo.invitations, ...items];
                    return userIds.length;
                });
        },
    },
});
