import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { useAppStore } from './stores/appStore';

import App from './App.vue';
import router from './router';
import Toast from 'vue-toastification';
import Antd from 'ant-design-vue';

// import MasonryWall from '@yeger/vue-masonry-wall';

import 'vue-toastification/dist/index.css';
import 'ant-design-vue/dist/antd.css';
import 'video.js/dist/video-js.css';
import './assets/styles/main.css';

import vClickOutside from 'click-outside-vue3';

const toastOptions = {
    maxToasts: 2,
    hideProgressBar: true,
    timeout: false,
    closeOnClick: false,
    draggable: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
};
const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
    store.$router = markRaw(router);
});

app.use(pinia);
app.use(Toast, toastOptions);
//app.use(MasonryWall);
app.use(vClickOutside);
app.use(Antd);

const appStore = useAppStore();
appStore.initAction().then(() => {
    app.use(router);
    app.mount('#app');
});
