import axios from 'axios';

const ROUTE_PREFIX = '/playlists';

function getPlaylists(params, signal = null) {
    const getParams = new URLSearchParams(params).toString();
    return axios.get(`${ROUTE_PREFIX}?${getParams}`, { signal }).then(({ data }) => data);
}

function getPlaylistInfo(playlistId) {
    return axios.get(`${ROUTE_PREFIX}/${playlistId}`).then(({ data }) => data);
}

function createPlaylist(data) {
    return axios.post(`${ROUTE_PREFIX}`, data).then(({ data }) => data);
}

function updatePlaylist(playlistId, data) {
    return axios.patch(`${ROUTE_PREFIX}/${playlistId}`, data);
}

function getPlaylistAssets(playlistId, params) {
    const getParams = new URLSearchParams(params).toString();
    return axios.get(`${ROUTE_PREFIX}/${playlistId}/assets?${getParams}`).then(({ data }) => data);
}

function addAssetToPlaylist(playlistId, data) {
    return axios.post(`${ROUTE_PREFIX}/${playlistId}/assets`, data);
}

function removeAssetFromPlaylist(playlistId, data) {
    return axios.delete(`${ROUTE_PREFIX}/${playlistId}/assets`, { data });
}

function inviteUsersToPlaylist(playlistId, data) {
    return axios
        .post(`${ROUTE_PREFIX}/${playlistId}/invitations`, data)
        .then(({ data }) => data.items);
}

function _respondOnInvite(playlistId, invitationId, data) {
    return axios.patch(`${ROUTE_PREFIX}/${playlistId}/invitations/${invitationId}`, data);
}

function acceptInviteToPlaylist({ playlistId, invitationId, status }) {
    return _respondOnInvite(playlistId, invitationId, { status });
}

function declineInviteToPlaylist({ playlistId, invitationId, status }) {
    return _respondOnInvite(playlistId, invitationId, { status });
}

function unsubscribeFromPlaylist(playlistId) {
    return axios.delete(`${ROUTE_PREFIX}/${playlistId}/subscription`);
}

function getPlaylistsCounters() {
    return axios.get('/counters/playlists');
}

function clearPlaylistItemsNovelty(playlistId) {
    return axios.post(`${ROUTE_PREFIX}/${playlistId}/novelty_clearing`);
}

function deletePlaylist(playlistId) {
    return axios.delete(`${ROUTE_PREFIX}/${playlistId}`);
}

function subscribeToPlaylist(playlistId) {
    return axios.post(`${ROUTE_PREFIX}/${playlistId}/subscription`);
}

export {
    getPlaylists,
    createPlaylist,
    addAssetToPlaylist,
    removeAssetFromPlaylist,
    getPlaylistInfo,
    updatePlaylist,
    getPlaylistAssets,
    inviteUsersToPlaylist,
    acceptInviteToPlaylist,
    declineInviteToPlaylist,
    unsubscribeFromPlaylist,
    getPlaylistsCounters,
    clearPlaylistItemsNovelty,
    deletePlaylist,
    subscribeToPlaylist,
};
