import { ASSET_TYPE_VIDEO } from '@/enums/assetTypeEnum';
import imagePath from '@/assets/img/assets/asset-poster-placeholder.svg';

export function assetImage(asset) {
    if (asset.assetType === ASSET_TYPE_VIDEO) {
        return asset.thumbnailUrl || imagePath;
    } else {
        return asset.url || imagePath;
    }
}

/**
 * Retrieves the display name for an asset based on its information and preview status.
 *
 * @param {object} assetInfo - Information about the asset, including displayName, brand, assetType, adStartDate, and name.
 * @param {boolean} preview - A boolean indicating whether the asset is in preview mode.
 * @returns {string} - The display name for the asset, considering various parameters.
 */
export function getAssetDisplayName(assetInfo, preview) {
    const displayName = assetInfo?.displayName;

    if (displayName) return displayName;

    const { brand, assetType, adStartDate, name } = assetInfo || {};

    if (brand && assetType) {
        const brandName = !preview ? `${brand.name} - ` : '';
        let startDate = adStartDate ? ` - ${adStartDate.split('T')[0]}` : '';

        if (startDate && startDate.includes('0001-01-01')) {
            startDate = ' - date unknown';
        }

        return `${brandName}${assetType}${startDate}`;
    }
    return name;
}
