<template>
    <div
        @click="onClickItem"
        :class="{ 'from-search': fromSearch && !isPending && !isSubscribed && !isOwned }"
        class="subscriber-item"
    >
        <ant-avatar class="subscriber-item__avatar" :size="40" :src="subscriber.avatarUrl">
            {{ userInitials }}
        </ant-avatar>
        <div class="subscriber-item__info">
            <div class="info">
                <div class="subscriber-name">
                    {{ subscriber.username }}
                </div>
                <div class="subscriber-stat">
                    <template v-if="fromSearch">
                        <div v-if="isPending" class="pending">Pending</div>
                        <div v-else-if="isSubscribed" class="subscribed">Already subscribed</div>
                        <div v-else-if="isOwned" class="owned">Owner</div>
                        <template v-else>{{ subscriber.email }}</template>
                    </template>
                    <template v-else>
                        <template v-if="isViewed"> All assets viewed</template>
                        <template v-else>
                            <span>{{
                                `${subscriber.assetStatistics.viewed}/${subscriber.assetStatistics.total} viewed`
                            }}</span>
                        </template>
                    </template>
                </div>
            </div>
            <div class="actions">
                <template v-if="fromSearch">
                    <time-dashed-icon-component v-if="isPending" class="pending-action" />
                    <star-icon-component v-else-if="isOwned" class="own-action" />
                    <double-check-icon-component
                        v-else-if="isSubscribed"
                        class="subscribed-action"
                    />
                    <check-circle-icon-component v-else-if="isInvited" class="invited-action" />
                    <plus-circle-icon-component v-else class="add-action" />
                </template>
                <template v-else>
                    <template v-if="!isViewed">
                        <progress-component
                            type="circle"
                            :show-info="false"
                            :stroke-color="'var(--asset-color-text-main)'"
                            :trail-color="'#E7E9E9'"
                            :percent="
                                (subscriber.assetStatistics.viewed /
                                    subscriber.assetStatistics.total) *
                                100
                            "
                            :stroke-width="10"
                            :width="20"
                        >
                        </progress-component>
                    </template>
                    <template v-else>
                        <double-check-icon-component
                            style="color: var(--asset-color-text-main)"
                            class="icon-check action-icon"
                        />
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { Avatar as AntAvatar, Progress as ProgressComponent } from 'ant-design-vue';
    import TimeDashedIconComponent from '@/components/ui/icons/TimeDashedIconComponent.vue';
    import PlusCircleIconComponent from '@/components/ui/icons/PlusCircleIconComponent.vue';
    import CheckCircleIconComponent from '@/components/ui/icons/CheckCircleIconComponent.vue';
    import DoubleCheckIconComponent from '@/components/ui/icons/DoubleCheckIconComponent.vue';
    import StarIconComponent from "@/components/ui/icons/StarIconComponent.vue";

    import { computed } from 'vue';

    const props = defineProps({
        subscriber: {
            type: Object,
            default: () => ({}),
        },
        fromSearch: {
            type: Boolean,
            default: false,
        },
        isPending: {
            type: Boolean,
            default: false,
        },
        isInvited: {
            type: Boolean,
            default: false,
        },
        isSubscribed: {
            type: Boolean,
            default: false,
        },
        isOwned : {
            type: Boolean,
            default: false,
        }
    });

    const emit = defineEmits(['inviting', 'uninviting']);

    const userInitials = computed(() => {
        const [firstName = '', lastName = ''] = (props.subscriber?.username || '').split(' ');
        return `${firstName[0] || ''}${lastName ? '' + lastName[0] : ''}`;
    });

    const isViewed = computed(() => {
        return (
            props.subscriber.assetStatistics.total / props.subscriber.assetStatistics.viewed === 1
        );
    });

    const onClickItem = () => {
        if (props.fromSearch && !props.isPending && !props.isSubscribed && !props.isOwned) {
            if (props.isInvited) {
                emit('uninviting', props.subscriber);
            } else {
                emit('inviting', props.subscriber);
            }
        }
    };
</script>

<style lang="scss">
    .subscriber-item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        gap: 12px;

        &.from-search {
            cursor: pointer;
        }

        &__avatar {
            background-color: #e3f1fa !important;
            color: rgba(0, 0, 0, 0.7) !important;
            font-weight: 500 !important;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 52px);

            .info {
                width: calc(100% - 25px);

                .subscriber-name {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    color: #212127;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .subscriber-stat {
                    margin-top: 2px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #858787;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    span {
                        color: #212127;
                    }

                    .pending {
                        color: #e7bd14;
                    }

                    .owned {
                      color: #3152c1;
                    }

                    .subscribed {
                        color: #1ed760;
                    }
                }
            }

            .actions {
                .pending-action {
                    color: #e7bd14;
                }

                .subscribed-action {
                    color: #1ed760;
                }

                .add-action {
                    color: #858787;
                    cursor: pointer;
                }

                .own-action {
                  color: #3152c1;
                }

                .invited-action {
                    color: var(--asset-color-text-main);
                    cursor: pointer;
                }
            }
        }
    }
</style>
