<template>
    <skeleton-button
        v-if="isLoading"
        class="more-actions-skeleton-button"
        :active="false"
        :loading="true"
    />

    <popover-component
        v-else
        v-model:visible="popoverVisible"
        class="popover-item"
        placement="bottomRight"
        trigger="click"
    >
        <template #content>
            <div class="popover-content">
                <slot :close="closePopover" name="menu-items" />
            </div>
        </template>
        <slot name="action-item" />
    </popover-component>
</template>

<script setup>
    import { SkeletonButton, Popover as PopoverComponent } from 'ant-design-vue';
    import { ref, watch } from 'vue';

    defineProps({
        isLoading: {
            type: Boolean,
            default: false,
        },
    });

    const emits = defineEmits(['menu-toggle']);

    const popoverVisible = ref(false);

    const closePopover = () => {
        popoverVisible.value = false;
    };

    watch(
        () => popoverVisible.value,
        (val) => {
            emits('menu-toggle', val);
        }
    );
</script>

<style lang="scss">
    .more-actions {
        .more-actions-button {
            width: 36px;
            height: 36px;
            min-height: 36px;
            min-width: 36px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.07);
            border: none;

            .dots-horizontal-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .more-actions-skeleton-button {
        .ant-skeleton-button {
            width: 36px;
            height: 36px;
            min-height: 36px;
            min-width: 36px;
            border-radius: 100px;
        }
    }

    .ant-popover-content {
        width: 163px;

        .ant-popover-inner {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            .ant-popover-inner-content {
                padding: 7px 0;

                .popover-content {
                    &__item {
                        padding: 5px 12px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        color: var(--asset-color-text-main);

                        .item-icon {
                            margin-right: 8px;
                            color: var(--asset-color-text-main);
                        }

                        .item-text {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                            color: var(--asset-color-text-main);
                        }

                        &:hover {
                            background-color: #f3f4f4;
                        }
                    }
                }
            }
        }

        .ant-popover-arrow {
            top: -7px !important;
            width: 20px;
            height: 7px;
            right: 7px !important;

            .ant-popover-arrow-content {
                width: 12px;
                height: 10px;
                transform: translateY(4.24264069px) rotate(45deg);
            }
        }
    }
</style>
