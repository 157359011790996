import axios from 'axios';

const ROUTE_PREFIX = '/assets/images';

function getImageAsset(assetId) {
    return axios
        .get(`${ROUTE_PREFIX}/${assetId}`)
        .then(({ data }) => ({ ...data, assetType: 'image' }));
}

function voteImageAsset(assetId, vote) {
    return axios.put(`${ROUTE_PREFIX}/${assetId}/vote`, { like: vote });
}

function markImageAssetAsViewed(assetId) {
    return axios.put(`${ROUTE_PREFIX}/${assetId}/view`);
}

export { getImageAsset, voteImageAsset, markImageAssetAsViewed };
