export const PLAYLIST_TYPE_ALL = 'all';
export const PLAYLIST_TYPE_OWNED = 'owned';
export const PLAYLIST_TYPE_SUBSCRIBED = 'subscribed';
export const PLAYLIST_TYPE_PENDING = 'pending_approval';
export const PLAYLIST_TYPE_STORY_DATA_PROJECTS = 'story_data_projects';

const TYPE_TO_REQUEST_MAPPER = {};
TYPE_TO_REQUEST_MAPPER[PLAYLIST_TYPE_ALL] = '';
TYPE_TO_REQUEST_MAPPER[PLAYLIST_TYPE_OWNED] = PLAYLIST_TYPE_OWNED;
TYPE_TO_REQUEST_MAPPER[PLAYLIST_TYPE_SUBSCRIBED] = PLAYLIST_TYPE_SUBSCRIBED;
TYPE_TO_REQUEST_MAPPER[PLAYLIST_TYPE_PENDING] = PLAYLIST_TYPE_PENDING;
TYPE_TO_REQUEST_MAPPER[PLAYLIST_TYPE_STORY_DATA_PROJECTS] = PLAYLIST_TYPE_STORY_DATA_PROJECTS;

export { TYPE_TO_REQUEST_MAPPER };
