<template>
    <div v-if="assetToAddLength > 0" class="add-to-playlist-footer">
        <div class="selection-info">
            <a-badge :count="assetToAddLength" class="selected-items-count" :show-zero="true" />
            {{ `asset${assetToAddLength > 1 ? 's' : ''} selected` }}
        </div>
        <div class="selection-actions">
            <button-component @click="clearSelection" :type-button="'grey'"
                >Clear selection
            </button-component>
            <button-component @click="addToPlaylist" :type-button="'primary'">
                <template #leftIcon>
                    <add-to-playlist-icon-component />
                </template>
                Add to playlist
            </button-component>
        </div>
    </div>
</template>

<script setup>
    import ButtonComponent from '@/components/ui/ButtonComponent.vue';
    import AddToPlaylistIconComponent from '@/components/ui/icons/AddToPlaylistIconComponent.vue';
    import { useAddToPlaylistModalStore } from '@/stores/modals/AddToPlaylistModalStore';
    import { storeToRefs } from 'pinia';

    const addToPlaylistModalStore = useAddToPlaylistModalStore();
    const { assetToAddLength } = storeToRefs(addToPlaylistModalStore);

    const clearSelection = () => {
        addToPlaylistModalStore.$reset();
    };

    const addToPlaylist = () => {
        addToPlaylistModalStore.showModal();
    };
</script>

<style lang="scss">
    .add-to-playlist-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 80px;
        position: sticky;
        bottom: 0;
        background: #ffffff;
        border-top: 1px solid #e7e9e9;
        padding: 0 60px;
        z-index: 9999;

        .selection-info {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #000000;
            display: flex;
            gap: 7px;

            .selected-items-count {
                display: flex;
                //border-radius: 100px;
                border-radius: 12px;

                & .ant-badge-count {
                    display: flex;
                    top: -1px;
                    background: #212127;
                    padding: 2px 8px;
                    font-weight: 600 !important;
                    font-size: 12px !important;
                    line-height: 20px !important;
                    color: #ffffff;
                    height: 24px;
                    min-width: 28px;
                    justify-content: center;

                    border-radius: 12px;
                    //border-radius: 100px;

                    p {
                        font-weight: 600 !important;
                        font-size: 12px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }

        .selection-actions {
            display: flex;
            gap: 12px;
        }
    }

    @media (min-width: 1920.98px) {
        .add-to-playlist-footer {
            padding: 0 8%;
        }
    }
</style>
