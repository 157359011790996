import { defineStore } from 'pinia';
import axios from 'axios';
import videojs from 'video.js';
import AssetDownloadPlugin from '@/components/videoplayer/plugins/AssetDownloadPlugin.js';
import PlaylistPlugin from '@/components/videoplayer/plugins/PlaylistPlugin.js';
import VolumeManagerPlugin from '@/components/videoplayer/plugins/VolumeManagerPlugin.js';
//import ImageViewerPlugin from '@/components/videoplayer/plugins/ImageViewerPlugin.js';
import { getStoryDataCategories, getStoryDataFilters } from '@/api/searchApi';

import useToasts from '@/plugins/toast.js';
import { debounce as _debounce } from 'lodash';

import { useUserStore } from '@/stores/UserStore.js';

const $toast = useToasts();
let priorMessage = {
    type: null,
    message: '',
};

const debounceToast = _debounce(() => {
    const { type, message } = priorMessage;
    if (type && message) {
        $toast[type](message);
    }
    priorMessage = {
        type: null,
        message: '',
    };
}, 1000);

function fireToast(type, message) {
    if (!priorMessage.type || type === 'error') {
        priorMessage.type = type;
        priorMessage.message = message;
    }
    debounceToast();
}

export const useAppStore = defineStore('AppStore', {
    state: () => ({
        apiUrl: import.meta.env.VITE_APP_API_URL,
        filters: [],
        categories: [],
    }),
    actions: {
        initAction() {
            const userStore = useUserStore();

            axios.defaults.baseURL = this.apiUrl;
            axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            axios.defaults.withCredentials = true;
            axios.interceptors.request.use((config) => {
                return config;
            }, null);

            axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                async (error) => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                            case 404:
                                fireToast('warning', error.response.data.message);
                                this.$router.push({ name: 'Explore' });
                                break;
                            case 401:
                            case 403:
                                if (userStore.isLoggedIn) {
                                    await userStore.logout();
                                }
                                this.$router.push({ name: 'AuthLogin' });
                                break;
                            case 500:
                                fireToast(
                                    'error',
                                    'Please try again. If the problem occurs again - contact administrator'
                                );
                                break;
                            default:
                                fireToast('warning', error.response.data.message);
                        }
                    }
                    return Promise.reject(error);
                }
            );

            this.initPlayerPlugins();
            return userStore
                .getCurrentUser()
                .then(() => {
                    this.loadGlobalData();
                })
                .catch(() => {});
        },
        initPlayerPlugins() {
            videojs.registerPlugin('AssetDownloadPlugin', AssetDownloadPlugin);
            videojs.registerPlugin('PlaylistPlugin', PlaylistPlugin);
            videojs.registerPlugin('VolumeManagerPlugin', VolumeManagerPlugin);
            //videojs.registerPlugin('ImageViewerPlugin', ImageViewerPlugin);
        },
        setDocumentTitle(title = 'StoryData') {
            document.title = title;
        },
        loadGlobalData() {
            getStoryDataCategories().then((categories) => {
                this.categories = categories;
            });
            getStoryDataFilters().then((filters) => {
                this.filters = filters;
            });

            if (import.meta.env.VITE_APP_SD_ENV !== 'local') {
                const userStore = useUserStore();
                const userObj = userStore.cuttedUserObject;
                const userEmail = userStore.userEmail;
                FS.identify(`${userEmail || userObj.id}`, {
                    displayName: userObj.username,
                    email: userEmail,
                    environment_str: import.meta.env.VITE_APP_SD_ENV,
                });
            }
        },
    },
});
