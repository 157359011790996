<template>
    <div class="app-container">
        <component :is="layout" />
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { ref, watch, markRaw } from 'vue';
    import LayoutLookup from '@/layouts/LayoutLookup';

    const layout = ref(null);
    const route = useRoute();

    watch(
        () => route.meta.layout,
        (val) => {
            if (val) {
                layout.value = markRaw(LayoutLookup[val]);
            }
        },
        { immediate: true }
    );
</script>
