<template>
    <div style="margin-right: 24px; height: 20px">
        <input-component
            v-show="isActive"
            ref="globalSearchInput"
            class="global-search-input"
            :class="{
                'with-data-collapsed': isCutOffWidth,
            }"
            v-model:value="searchValue"
            :is-search="true"
            :force-display-icons="true"
            :placeholder="'Search by keyword'"
            v-click-outside="onClickOutside"
            @suffix-clicked="onSuffixClicked"
            @click="onClick"
            @keydown="onKeyDown"
        />
        <search-icon-component
            class="search-icon init-input"
            v-show="!isActive"
            @click="onInitSearch"
        />
        <div v-show="isActive" style="width: 20px"></div>
    </div>
</template>
<script setup>
    import InputComponent from './InputComponent.vue';
    import SearchIconComponent from '@/components/ui/icons/SearchIconComponent.vue';
    import { useGlobalSearchStore } from '@/stores/searchStore';
    import { computed, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const router = useRouter();
    const route = useRoute();

    const globalSearchStore = useGlobalSearchStore();
    const globalSearchInput = ref(null);
    const searchValue = ref('');
    const isActive = ref(false);
    const isCutOffWidth = ref(false);

    const isEmptySearchValue = computed(() => {
        return !searchValue.value.length;
    });

    const onInitSearch = () => {
        isActive.value = true;
        setTimeout(() => {
            globalSearchInput.value.$el.querySelector('.ant-input').focus();
        }, 10);
    };

    const onSuffixClicked = (val) => {
        if (!val) {
            isActive.value = false;
        } else {
            setTimeout(() => {
                globalSearchInput.value.$el.querySelector('.ant-input').focus();
            }, 10);
            isCutOffWidth.value = false;
        }
    };

    const onClick = () => {
        isCutOffWidth.value = false;
    };

    const onClickOutside = (event) => {
        if (
            (event.target?.nodeName === 'path' &&
                event.target?.ownerSVGElement?.nodeName === 'svg' &&
                event.target?.ownerSVGElement?.classList.value === 'search-icon init-input') ||
            (event.target?.nodeName === 'svg' &&
                event.target?.classList.value === 'search-icon init-input')
        ) {
            //Not handle click
        } else {
            if (isActive.value) {
                if (!isEmptySearchValue.value) {
                    isCutOffWidth.value = true;
                } else {
                    isActive.value = false;
                }
            }
        }
    };

    const onKeyDown = (event) => {
        //Enter
        if (event.keyCode === 13 && !isEmptySearchValue.value) {
            //We don`t need to perform search because it will automatically ru when search page is opened
            isCutOffWidth.value = true;
            router.push({
                name: 'GlobalSearch',
                params: { searchValue: searchValue.value },
                //params: { searchValue: encodeURI(searchValue.value) },
            });
            globalSearchInput.value.$el.querySelector('.ant-input').blur();
        }
    };

    onMounted(() => {
        if (route.name === 'GlobalSearch') {
            globalSearchStore.doSearch({ search: route.params.searchValue });
            searchValue.value = route.params.searchValue;
            //searchValue.value = decodeURI(route.params.searchValue);
            isCutOffWidth.value = true;
            isActive.value = true;
        }
    });
</script>

<style lang="scss">
    .global-search-input {
        margin-bottom: 0;
        flex: 1;
        position: absolute !important;
        right: 0 !important;
        bottom: calc(50% - 18px) !important;
        z-index: 22222;
        width: 480px !important;
        box-shadow: 0px 0px 15px 15px var(--color-background) !important;

        &:hover {
            box-shadow: 0px 0px 15px 15px var(--color-background) !important;
        }

        .ant-input-prefix {
            svg {
                color: var(--asset-color-text-main);
            }

            cursor: pointer;
        }

        &.with-data-collapsed {
            width: 200px !important;
        }
    }

    .search-icon {
        height: 20px;

        &.init-input {
            cursor: pointer;
        }
    }
</style>
