import axios from 'axios';

const ROUTE_PREFIX = '/users';

function getUserList(params) {
    const getParams = new URLSearchParams(params).toString();
    return axios.get(`${ROUTE_PREFIX}?${getParams}`).then(({ data }) => data);
}

export { getUserList };
