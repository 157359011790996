import { ASSET_TYPE_VIDEO, ASSET_TYPE_IMAGE } from '@/enums/assetTypeEnum.js';

const MAPPER = {};

Object.defineProperty(MAPPER, ASSET_TYPE_VIDEO, {
    value: {
        assetProcessorData: 'videoProcessorData',
    },
});

Object.defineProperty(MAPPER, ASSET_TYPE_IMAGE, {
    value: {
        assetProcessorData: 'imageProcessorData',
    },
});

export default function assetFieldByType(field, type) {
    if (!field || !type) return null;
    return MAPPER[type][field];
}
