import { defineStore } from 'pinia';
import { searchApi } from '@/api';
import { randomSeed } from '@/helpers/pagination';

export const useGlobalSearchStore = defineStore('GlobalSearchStore', {
    state: () => ({
        isLoading: false,
        assets: {
            items: [],
            meta: null,
        },
        playlists: {
            items: [],
            meta: null,
        },
        brands: {
            items: [],
            meta: null,
        },
        sort_seed: randomSeed(),
    }),
    getters: {
        assetsMeta: (state) => state.assets?.meta,
        assetsItems: (state) => state.assets?.items,

        brandsMeta: (state) => state.brands?.meta,
        brandsItems: (state) => state.brands?.items,

        playlistsMeta: (state) => state.playlists?.meta,
        playlistsItems: (state) => state.playlists?.items,

        totalResults: function () {
            return (
                (this.assetsMeta?.total || 0) +
                (this.brandsMeta?.total || 0) +
                (this.playlistsMeta?.total || 0)
            );
        },
    },
    actions: {
        async doSearch(params = {}) {
            this.isLoading = true;

            if (!params.per_page) {
                params.per_page = 20;
            }

            if (!params.sort_seed && this.sort_seed) {
                params.sort_seed = this.sort_seed;
            }

            params.page = 1;

            const {
                groups: { playlists, assets, brands },
            } = await searchApi.getSearchResults(params);
            this.playlists = playlists;
            this.assets = assets;
            this.brands = brands;

            this.isLoading = false;
        },

        updateBrandsFavoriteStatus(brandId, isFavorite) {
            const index = this.brands.items.findIndex((it) => it.id === brandId);
            if (index >= 0) {
                this.brands.items[index].favorites = {
                    ...this.brands.items[index].favorites,
                    favorite: isFavorite,
                };
            }
        },

        updateAssets({ meta, items }, infinity = false) {
            if (infinity) {
                this.assets.items = [...this.assets.items, ...items];
            } else {
                this.assets.items = items;
            }
            this.assets.meta = meta;
        },
        updateBrands({ meta, items }, infinity = false) {
            if (infinity) {
                this.brands.items = [...this.brands.items, ...items];
            } else {
                this.brands.items = items;
            }
            this.brands.meta = meta;
        },
        updatePlaylists({ meta, items }, infinity = false) {
            if (infinity) {
                this.playlists.items = [...this.playlists.items, ...items];
            } else {
                this.playlists.items = items;
            }
            this.playlists.meta = meta;
        },
    },
});
