function insertControls({ el, player }) {
    if (el) {
        let controlBar = el.querySelector('.vjs-control-bar');
        let insertBeforeNode = el.querySelector('.vjs-fullscreen-control');

        const prevButton = createButton('prev', 'previous', 'Previous Asset');
        const nextButton = createButton('next', 'next', 'Next Asset');

        prevButton.addEventListener('click', () => {
            player.trigger('prev-asset');
        });

        // prevButton.disabled = 'disabled';

        nextButton.addEventListener('click', () => {
            player.trigger('next-asset');
        });

        controlBar.insertBefore(prevButton, insertBeforeNode);
        controlBar.insertBefore(nextButton, insertBeforeNode);
    } else {
        console.error('No Element Tag');
    }
}

function createButton(buttonClassNamePrefix, iconClassNamePrefix, toolTipTitle) {
    const newButton = document.createElement('button');
    newButton.className = `vjs-${buttonClassNamePrefix}-control vjs-control vjs-button`;
    newButton.title = toolTipTitle;

    let iconElement = document.createElement('span');
    iconElement.className = `vjs-icon-placeholder vjs-icon-${iconClassNamePrefix}-item`;
    iconElement.ariaHidden = 'true';

    let buttonAltText = document.createElement('span');
    buttonAltText.className = 'vjs-control-text';
    buttonAltText.ariaLive = 'polite';
    buttonAltText.innerText = toolTipTitle;

    newButton.appendChild(iconElement);
    newButton.appendChild(buttonAltText);

    return newButton;
}

export default function PlaylistPlugin(options) {
    insertControls(options);
}
