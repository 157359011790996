<template>
    <layout class="layout">
        <layout-content class="layout-auth">
            <router-view :key="route.fullPath" />
        </layout-content>
    </layout>
</template>

<script setup>
    import { LayoutContent, Layout } from 'ant-design-vue';
    import { useRoute } from 'vue-router';

    const route = useRoute();
</script>

<style lang="scss">
    .layout {
        min-height: 100vh;

        & .layout-auth {
            padding: 0;
            background-color: var(--color-background);
        }
    }
</style>
