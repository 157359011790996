import axios from 'axios';

const ROUTE_PREFIX = '/assets/videos';

function getVideoAsset(assetId) {
    return axios
        .get(`${ROUTE_PREFIX}/${assetId}`)
        .then(({ data }) => ({ ...data, assetType: 'video' }));
}

function voteVideoAsset(assetId, vote) {
    return axios.put(`${ROUTE_PREFIX}/${assetId}/vote`, { like: vote });
}

function markVideoAssetAsViewed(assetId) {
    return axios.put(`${ROUTE_PREFIX}/${assetId}/view`);
}

function getVideoExtras(assetID) {
    return axios.get(`${ROUTE_PREFIX}/${assetID}/extras`).then(({ data }) => data);
}

export { getVideoAsset, voteVideoAsset, markVideoAssetAsViewed, getVideoExtras };
