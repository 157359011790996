import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/appStore';
import { authApi, playlistsApi } from '@/api';
import { STATUS_DECLINED, STATUS_ACCEPTED } from '@/enums/playlistInviteStatus';

const userDataInit = {
    id: null,
    fullName: '',
    username: '',
    email: '',
    picture: '',
    avatarUrl: '',
};

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        userData: userDataInit,
        isLoggedIn: false,
    }),
    getters: {
        userInitials: (state) => {
            const [firstName = '', lastName = ''] = state.userData.fullName.split(' ') || [
                'Story',
                'Data',
            ];
            return `${firstName[0] || ''}${lastName ? '' + lastName[0] : ''}`;
        },
        currentUserId: (state) => state.userData.id,
        cuttedUserObject: (state) => ({
            id: state.userData.id,
            avatarUrl: state.userData.avatarUrl,
            username: state.userData.username,
        }),
        userEmail: (state) => state.userData.email,
    },
    actions: {
        async login(token) {
            await authApi.login(token);
            return await this.getCurrentUser().then(() => {
                useAppStore().loadGlobalData();
            });
        },
        async logout() {
            this.userData = userDataInit;
            this.isLoggedIn = false;

            const redirect_url = window.location.pathname + window.location.search;
            window.localStorage.setItem('redirect_url', redirect_url);

            await authApi.logout();
        },
        async getCurrentUser() {
            const { data } = await authApi.getCurrentUser();
            this.isLoggedIn = true;
            this.userData = {
                id: data.id,
                fullName: data.username,
                username: data.username,
                email: data.email || '',
                picture: data.avatarUrl,
                avatarUrl: data.avatarUrl,
            };
        },
        acceptInviteToPlaylist(playlistId, invitationId) {
            const data = {
                playlistId,
                invitationId,
                status: STATUS_ACCEPTED,
            };
            return playlistsApi.acceptInviteToPlaylist(data);
        },
        declineInviteToPlaylist(playlistId, invitationId) {
            const data = {
                playlistId,
                invitationId,
                status: STATUS_DECLINED,
            };
            return playlistsApi.declineInviteToPlaylist(data);
        },
        subscribeToPlaylist(playlistID) {
            return playlistsApi.subscribeToPlaylist(playlistID);
        },
    },
});
