import axios from 'axios';

const RECOMMENDATION_DEFAULT_LIMIT = 12;
const EXPLORATION_DEFAULT_LIMIT = 16;

function getAssetRecommendations(
    filterId,
    excluded_asset_id = 0,
    per_page = RECOMMENDATION_DEFAULT_LIMIT
) {
    return axios
        .get(
            `/assets/recommendations?filter_id=${filterId}&per_page=${per_page}${
                excluded_asset_id ? `&excluded_asset_id=${excluded_asset_id}` : ''
            }`
        )
        .then(({ data }) => data.items);
}

function getExploration(per_page = EXPLORATION_DEFAULT_LIMIT, page = 1, orderBy = 'creation_date') {
    return axios
        .get(`/exploration?per_page=${per_page}&page=${page}&order=${orderBy}`)
        .then(({ data }) => data.items);
    //.then(({ data }) => [...data.items, ...data.items, ...data.items]);
}

function getTopAssets() {
    return axios.get(`/assets/top`).then(({ data }) => {
        if (data.items && data.items[0]) {
            return {
                ...data.items[0],
                promoPrize: 'Award winner',
            };
        } else {
            return null;
        }
    });
}

function getExplorationPlaylists() {
    return axios.get(`/exploration/playlists`).then(({ data }) => data);
}

export { getAssetRecommendations, getExploration, getTopAssets, getExplorationPlaylists };
